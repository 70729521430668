import React, { useState } from 'react';
import { Button, Popover } from 'antd';
const PopOverComponent = (props) => {
  const { popUp, element, trigger } = props; // Destructure props for better readability

  const [open, setOpen] = useState(false);


  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
    if (!newOpen) {
      hide(); // Call hide function if popover is closed
    }
  };

  return (
    <Popover
      content={<>{props.popUp}</>}
      title={""}
      // trigger="click"
      trigger={trigger || "click"} // Default to "click" if no trigger is passed

      open={props.open}
      onOpenChange={props.handleOpenChange}
    >
        {props.element}
    </Popover>
  );
};
export default PopOverComponent;