import { CREATE_ADMIN_INSTRUCTOR,INSTRUCTOR_CRUD_STATUS,GET_ALL_ADMIN_INSTRUCTORS ,
    INSTRUCTORS_APPROVED,INSTRUCTORS_REJECTED
} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';



export const getAllAdminInstructors = (data)=>{
    return{
        type:GET_ALL_ADMIN_INSTRUCTORS,
        payload: data
    }
}

export const instructorCrudStatuss = (data)=>{
    return{
        type: INSTRUCTOR_CRUD_STATUS,
        payload: data
    }
}

export const retrieveAdminInstructors = ()=>{
    let payload = {
        "operation_type":"retrieve",
        "tenant_id": window.localStorage.getItem("tenant_Id")
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_enquiry",payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(getAllAdminInstructors(res?.data?.data))
            }else{
                dispatch(getAllAdminInstructors([]))
   
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const createAdminInstructor = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/instructor_enquiry',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(instructorCrudStatuss(res?.data))
                dispatch(retrieveAdminInstructors())
            }

            if (res?.data?.res_status == false) {
                dispatch(instructorCrudStatuss(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const deleteAdminInstructor = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/instructor_admin',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(instructorCrudStatuss(res?.data))
                dispatch(retrieveAdminInstructors())
            }

            if (res?.data?.res_status == false) {
                dispatch(instructorCrudStatuss(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateAdminInstructor = (payload) =>{
    
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/instructor_admin',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(instructorCrudStatuss(res?.data))
                dispatch(retrieveAdminInstructors())
            }

            if (res?.data?.res_status == false) {
                dispatch(instructorCrudStatuss(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const uploadAdminBulkAction = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/instructor_admin',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(instructorCrudStatuss(res?.data))
                dispatch(retrieveAdminInstructors())
            }

            if (res?.data?.res_status == false) {
                dispatch(instructorCrudStatuss(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const adminInstructorBulkDelete = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/instructor_admin',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(instructorCrudStatuss(res?.data))
                dispatch(retrieveAdminInstructors())
            }

            if (res?.data?.res_status == false) {
                dispatch(instructorCrudStatuss(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const resetInstructorAdminPassword = (payload)=>{
    console.log(payload,"payload")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/instructor_admin',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(instructorCrudStatuss(res?.data))
            }

            if (res?.data?.res_status == false) {
                dispatch(instructorCrudStatuss(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}


export const instructorApprovedStatuss = (data)=>{
    return{
        type: INSTRUCTORS_APPROVED,
        payload: data
    }
}


export const instructorApproved = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/instructor_enquiry',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(instructorCrudStatuss(res?.data))
                dispatch(retrieveAdminInstructors())
            }

            if (res?.data?.res_status == false) {
                dispatch(instructorCrudStatuss(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const instructorRejectedStatuss = (data)=>{
    return{
        type: INSTRUCTORS_REJECTED,
        payload: data
    }
}


export const instructorRejected = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/instructor_enquiry',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(instructorCrudStatuss(res?.data))
                dispatch(retrieveAdminInstructors())
            }

            if (res?.data?.res_status == false) {
                dispatch(instructorCrudStatuss(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}