import React, { useEffect, useState } from 'react'
import { avatarPic } from '../../../images'
import { InputComponent } from '../../../commonComponents'

import './instructorList.css';
import { Spin } from 'antd';

const InstructorList = (props) => {
    let { instructorGroupList } = props;

    const [data, setData] = useState();
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        if (instructorGroupList) {
            setData(instructorGroupList);
            setLoading(false); // Set loading to false once data is set
        } else {
            setData([]);
            setLoading(false); // Set loading to false if no data
        }
    }, [instructorGroupList]);

    const handleSearch = (e) => {
        const searchQuery = e?.target?.value.toLowerCase(); // Convert search query to lowercase for case-insensitive search
    
        if (searchQuery) {
            // Filter the instructorGroupList based on the search query
            const filteredData = instructorGroupList.filter(item =>
                item?.batch_name.toLowerCase().includes(searchQuery) // Adjust 'batch_name' according to your data structure
            );
    
            // Update the data state with the filtered result
            setData(filteredData);
        } else {
            // If search query is empty, reset data to the original instructorGroupList
            setData(instructorGroupList);
        }
    };

    return (
        <div>
            <div className='chatHeader'>
                <div><h3 style={{ fontSize: '20px', fontWeight: 'bold' }}>Chat</h3></div>
                <div>
                    <InputComponent placeholder={"Search"} onChange={handleSearch} />
                </div>
            </div>

            <div className='listOfUsers'>
                {loading ? ( // Show Spin if loading
                    <div className='text-center' style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                        <Spin />
                    </div>
                ) : (
                    data && data.length > 0 ? (
                        data.map((item, index) => (
                            <div key={index} className='allUsers' onClick={() => props.handleGroupChat(item)}>
                                <div>
                                    <img
                                        src={item?.course_thumbnail ? item.course_thumbnail : avatarPic}
                                        style={{ width: '30px', height: "25px", borderRadius: "4px" }}
                                        alt="Thumbnail"
                                    />
                                </div>
                                <div className='dataContent'>
                                    <div><b>{item.batch_name}</b></div>
                                </div>
                                <div className='chatTimeDetails'>
                                    <div><p></p></div>
                                    <div><p></p></div>
                                </div>
                            </div>
                        ))
                    ) : (

                        <div className='text-center' style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                        <Spin />
                    </div>
                        // <div style={{ textAlign: "center", marginTop: "10px" }}>No instructors found</div>
                    )
                )}
            </div>
        </div>
    );
}

export default InstructorList;
