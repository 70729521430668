import {GET_ACTIVE_USER_REPORTS,GET_STUDENT_ATTENDANCE,GET_ALL_BATCHES,GET_ALL_STUDENT,GET_ALL_STUDENT_ATTENDANCE,
    GET_ALL_INSTRUCTOR_ATTENDANCE,GET_ALL_INSTRUCTOR
} from "./actionTypes";

 const ActiveUserReportsReducer = (state = [],action)=>{

    switch (action.type) {
        case GET_ACTIVE_USER_REPORTS:
            
            return {
                adminActiveUserReports: action.payload
            }

            case GET_STUDENT_ATTENDANCE:
            return {
                adminStudentAttendance: action.payload
            }

            case GET_ALL_BATCHES:
            return {
                getAllBatches: action.payload
            }

            case GET_ALL_STUDENT:
                return {
                    getAllStudent: action.payload
                }

                case GET_ALL_STUDENT_ATTENDANCE:
                    return {
                        getAllStudentAttendance: action.payload
                    }

                    case GET_ALL_INSTRUCTOR_ATTENDANCE:
                        return {
                            getAllInstructorAttendance: action.payload
                        }

                        case GET_ALL_INSTRUCTOR:
                            return {
                                getAllInstructor: action.payload
                            }
         default:
            return state;
    }
}

export  default ActiveUserReportsReducer;