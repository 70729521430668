import React, { useEffect, useState } from 'react'

import { Form ,notification} from 'antd';
import './studentSettings.css';
import { ButtonComponent, InputComponent } from '../../commonComponents';
import { Button, Input } from 'antd';
import { Modal, Upload } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordDetails, resetStudentDetailsResult, retrieveStudentProfileData, uploadProfileDetails, uploadStudentImage } from '../../redux/studentProfile/action';
import { useNavigate } from 'react-router-dom';
import ResetStudentPassword from './resetStudentPassword';

import {no_image} from '../../images';

let StudentSettings=()=> {

    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    let [changePassword,setChangePassword] = useState(false);
    let [notificationVisible,setNotificationVisible] = useState(false)

    let dispatch = useDispatch();

    let navigate = useNavigate();


    let basicProfile = useSelector((state)=>state?.studentProfileReducer?.studentProfile);

    let resetStatus = useSelector((state)=>state.studentProfileReducer?.resetStatus)

    let [profileValues,setProfileValues] = useState({
        first_name:"",
        last_name:"",
        email:"",
        phone_no:"",
        skills:"",
        current_password:"",
        new_password:"",
        confirm_password:"",
        profile_picture:"",uploadImage:""
    })

    let handleChange = (e,type)=>{
        if (e.target !== undefined) {
            const { name, value } = e.target;
            setProfileValues({ ...profileValues, [name]: value });
            form.setFieldsValue({[type.name]: e})

          } else {
            setProfileValues({ ...profileValues, [type.name]: e })
            form.setFieldsValue({[type.name]: e})

          }
          console.log(profileValues,"vaues")
    }

    useEffect(()=>{
        if (resetStatus?.res_status == true) {
       

          if(notificationVisible == true){
            setProfileValues({
              uploadImage:""
          })
            openNotification(true)
            setNotificationVisible(false)


            let payload = 
            {
                "operation_type":"view_student_data",
                "student_id": window.localStorage.getItem("student_id")
            }
            
           dispatch(retrieveStudentProfileData(payload)) 
          }




          // navigate("/studentDashboard")
        }
        if(resetStatus?.res_status == false){

          
          if(notificationVisible == true){
            setProfileValues({
              profile_picture:""
          })
          openNotification(false)
            setNotificationVisible(false)
          }
          // dispatch(resetStudentDetailsResult(undefined))
        }

        console.log(resetStatus,"status")
    },[resetStatus])

    const onFinish = (values) => {
        console.log(values,"here")
        if (values) {
            console.log(values)
            let changePasswordPayload = {
              "operation_type": "update_profile_picture",
              "op_field_details": {
                 "student_id": window.localStorage.getItem("student_id"),
                "profile_picture": profileValues.profile_picture
           }
       }
            dispatch(uploadProfileDetails(changePasswordPayload))
            setNotificationVisible(true)
            // openNotification(true)
        }
    }

    useEffect(()=>{
        let payload = 
        {
            "operation_type":"view_student_data",
            "student_id": window.localStorage.getItem("student_id")
        }
        
       dispatch(retrieveStudentProfileData(payload)) 
    },[])

    useEffect(()=>{
    if (basicProfile) {
      console.log(basicProfile,"uuuuuuuuu",basicProfile.data[0].profile_picture)
        // if(basicProfile){
        //   setProfileValues({...profileValues,"uploadImage":basicProfile.data[0]?.profile_picture})
        // }
        basicProfile && basicProfile?.data.map((item,index)=>{
        setProfileValues(item)
        form.setFieldsValue(item)
        })
        console.log(profileValues,"hey")        
        }
    },[basicProfile]);

    const convertToBase64 = (file) => {
      console.log(file,"abcsd")
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          if (file) {
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              resolve(fileReader.result);
            };
          }
         
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };

    let  onFileChange = async(event,type)=>{
      const file = event.target.files[0];
      const base64 = await convertToBase64(file);
      console.log(base64,"ppp")
      setProfileValues({ ...profileValues, profile_picture: base64});
        // decodeBase64(base64)
          console.log(profileValues,'regForm')
       }


       const openNotification = (status) => {
        if (status == true) {
          api.info({
            // message: `Category ${details.course_category_name}`,
            description:
              'Profile updated Successfully',
          });
        }

        if (status == false) {
          api.info({
            // message: `Category ${details.course_category_name}`,
            description:
              'Invalid credentials',
          });
        }
    }

    let resetPassword = () =>{
      setChangePassword(!changePassword)
    }

    let cancelResetPassword = () =>{
      navigate("/studentDashboard")
    }
  return (
    <>
    
    <div className='studentSettings'>
        {contextHolder}
        <div className='studentProfileHeader'>
          <div>
          <h3 className='accountSettingsText'>Profile Setting</h3>
          </div>
        <div className='alignRight'>
          <h4 onClick={()=>resetPassword()} className='underLine changePasswordStudent '>{!changePassword ? "Change Password?" : "ProfileUpdate"}</h4>
          </div>
        </div>
        {!changePassword ?
        <div className='studentSettingsContainer'>
            <Form 
            autoComplete="off"
            layout="vertical"
            form={form}
            onFinish={onFinish}>
                {/* <div className='profileStep1'> */}
                <h3 className='accountSettings'>Profile</h3>

                <div className='settingFormItem'>
                    
                       
                        <div className='profileInput'>
                    <Form.Item
                     label="First name"
                    name="first_name"
                    className='disabled'
                    rules={[
                        {
                        required: true,
                        message: 'First name is required',

                        },
                    ]}
                   
                    >
                    <div className='resetInput'>
                    <InputComponent disabled={true} name={"first_name"}  value={profileValues.first_name}
                     placeholder="Enter Full name"
                     onChange={(e)=>handleChange(e,{name:"first_name"})} 

                        />
                     </div>
                    </Form.Item>
                    </div>
               

                       
                        <div className='profileInput'>
                    <Form.Item
                     label="Last name"
                     name="last_name"
                     className='disabled'
                     rules={[
                        {
                        required: true,
                        message: 'Last name is required',
                        },
                    ]}
                   >
                    <div className='resetInput'>
                    <InputComponent  name={"last_name"} value={profileValues.last_name} 
                     placeholder="Enter Full name" disabled={true}
                     onChange={(e)=>handleChange(e,{name:"last_name"})} 

                        />
                     </div>
                    </Form.Item>
                    
                </div>
                       
                  <div className='profileInput'>
                    <Form.Item
                    label="Email"
                    name="email"
                    className='disabled'
                     rules={[
                        {
                        required: true,
                        message: 'Email is required',
                        type: "email",

                        },
                    ]}
                    >
                    <div className='resetInput'>
                    <InputComponent  name={"email"} value={profileValues.email} 
                     placeholder="Enter email" disabled={true}
                     onChange={(e)=>handleChange(e,{name:"email"})} 

                        />
                     </div>
                    </Form.Item>
                    </div>
                   
               

                        
                        <div className='profileInput'>
                    <Form.Item
                    label="Phone number"
                    name="phone_no"
                    className='disabled'
                     rules={[
                        {
                        required: true,
                        message: 'Phone name is required',

                        },
                    ]}
                    >
                    <div className='resetInput'>
                    <InputComponent  name={"phone_no"}  value={profileValues.phone_no ? profileValues.phone_no : ""}
                     placeholder="Enter Full name" disabled={true}
                     onChange={(e)=>handleChange(e,{name:"phone_no"})} 

                        />
                     </div>
                    </Form.Item>
                    
                
                        </div>
                   
                   
                        {/* <div className='profilePicture'>
                   
                   <div>
                            <img alt='noimage' 
                            src={`${(profileValues?.profile_picture )}`}
                            
                           width='200px' height={"100px"}/> 
                         </div>
                <div className='alignRight'>
                <InputComponent type="file" file title={""} name={"uploadImage"}  style={{"display": "none"}}
                         onChange={(e)=>onFileChange(e,{name: "uploadImage"})}
                         className="profileUpload"/>
                </div>
                </div> */}
                </div>

                <div style={{marginTop:"10px"}}></div>

                <div className='profilePicture'>

                <div>
  <img
    alt="noimage"
    src={profileValues?.profile_picture || no_image}
    width="200px"
    height="100px"
  />
</div>
                   
                   {/* <div>
                            <img alt='noimage' 
                            src={`${(profileValues?.profile_picture )}`}
                           width='200px' height={"100px"}/> 
                         </div> */}
                <div className='alignRight'>
                <InputComponent type="file" title={""} name={"uploadImage"}
                style={{"display": "none"}}
                         onChange={(e)=>onFileChange(e,{name: "uploadImage"})}
                         className="profileUpload"/>
                </div>
                </div>
                
                <Form.Item>
              <div className="studentProfileSave">
              <ButtonComponent value={'Cancel'} onClick={()=>cancelResetPassword()} type={"dashed"}/>

              <ButtonComponent value={'SAVE'}  htmlType={'Submit'}type={"primary"}/>

                </div>
              </Form.Item>
            </Form>
            

        </div>
         :<ResetStudentPassword/>}
    </div>
   
    </>
  )
}

export default StudentSettings;