import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import  {retrieveStudentAttendance}  from '../../../redux/adminReports/activeUserReports/action.js';
import  {retrieveAllBatchesDetails,retrieveAllInstructorDetails,retrieveAllInstructorAttendanceDetails}  from '../../../redux/adminReports/activeUserReports/action.js';

// import { retrieveStudentAttendance } from '../../../redux/adminReports/activeUserReports/action.js';
import { excel, plus, upload, searchIcon } from "../../../images";

import  TableComponent from '../../../commonComponents/TableComponent/TableComponent.js'
import { Table } from 'antd';
import * as XLSX from 'xlsx';
import ExportToExcel from '../../../commonComponents/XlsxFile/ExportToExcel'
import { message, Form, Button,notification,DatePicker ,Select} from 'antd';
import moment from 'moment';
import  InputComponent  from '../../../commonComponents/InputComponent/input.js';



const InStructoreAttendanceReports = (props) => {


    let dispatch = useDispatch();
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    let [startFromDate,setStartFromDate] = useState("");
    let [startToDate,setStartToDate] = useState("");

    let [allBatchesData,setAllBatchesData] = useState("");
    let [allStudentData,setAllStudentData] = useState("");
    let [allStudentAttendanceDataList,setAllStudentAttendanceDataList] = useState("");
    const [searchEntry,setSearchEntry] = useState("");


    const [selectedBatch, setSelectedBatch] = useState(null); // State for selected value
    const [selectedStudent, setSelectedStudent] = useState(null); // State for selected value


    const [fromDate, setFromDate] = useState(null); // State to manage the selected date
    const [toDate, setToDate] = useState(null); // State to manage the selected date


    let adminActiveUserReports = useSelector((state)=>state?.ActiveUserReportsReducer?.adminActiveUserReports);
    let allBatches = useSelector((state)=>state?.ActiveUserReportsReducer?.getAllBatches);
    let allStudents = useSelector((state)=>state?.ActiveUserReportsReducer?.getAllInstructor);
    let allStudentsAttendance = useSelector((state)=>state?.ActiveUserReportsReducer?.getAllInstructorAttendance);

    useEffect(()=>{


        if (allBatches) {
            setAllBatchesData(allBatches)
        }
    },[allBatches])

    useEffect(()=>{

        if (allStudents) {
            setAllStudentData(allStudents)
        }
    },[allStudents])

    useEffect(()=>{

        if (allStudentsAttendance) {
            setAllStudentAttendanceDataList(allStudentsAttendance?.data)
        }
    },[allStudentsAttendance])

    useEffect(()=>{

        let payload = {
            "operation_type":"retrieve_batch_id_and_name",
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }

        dispatch(retrieveAllBatchesDetails(payload))
    },[])

    useEffect(()=>{
        let payload = {
            "operation_type":"instructor_attendance_reports",
            "batch_id": "",
            "instructor_id":"",
            "from_date":"",
            "to_date":"",
            "tenant_id":window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveAllInstructorAttendanceDetails(payload))
    },[])

    const [adminStudentReports,setAdminStudentReports] = useState();



    useEffect(()=>{

        if (adminActiveUserReports) {
            setAdminStudentReports(adminActiveUserReports)
            console.log(adminActiveUserReports,"activeReprts")
        }
    },[adminActiveUserReports])

    const columns = [
        {
            title: 'Instructor Id',
            dataIndex: 'instructor_id',
            sorter:true,
            sorter: (a, b) => a.instructor_id -(b.instructor_id),
            filteredValue: [searchEntry],
            onFilter: (value,record)=>{
              return String(record.instructor_id).toLowerCase().includes(value.toLowerCase())||
                     String(record.batch_name).toLowerCase().includes(value.toLowerCase())||
                     String(record.fullname).toLowerCase().includes(value.toLowerCase())||
                     String(record.check_in_time).toLowerCase().includes(value.toLowerCase())||
                     String(record.date).toLowerCase().includes(value.toLowerCase())
                   }
        },
          {
            title: 'Batch Name',
            dataIndex: 'batch_name',
            sorter:true,
           sorter: (a, b) => a.batch_name .localeCompare(b.batch_name),
      
          },
          {
            title: 'Instructor Name',
            dataIndex: 'fullname',
            sorter:true,
           sorter: (a, b) => a.fullname .localeCompare(b.fullname),
      
          },
          {
            title: 'Join Time',
            dataIndex: 'check_in_time',
            sorter:true,
           sorter: (a, b) => a.check_in_time .localeCompare(b.check_in_time),
      
          },
          {
            title: 'Join Date',
            dataIndex: 'date',
            sorter: (a, b) => a.date - b.date, // Numeric sorting
            render: (text) => (
              <span>{new Date(text).toLocaleDateString('en-CA')}</span> // Formats date as "YYYY-MM-DD"
            ),
          },
         

    ]


    const exportToExcel = (data, fileName) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      };
      
      
      const transformedItems = adminStudentReports?.map((item) => {
       
      
        return { 
           INSTRUCTOR_ID: item.instructor_id,
           BATCH_NAME:  item.batch_name,
          INSTRUCTOR_NAME:  item.instructor_name,
          MONTH_NAME: item.month_name,
          CLASS_COUNT:  item.class_count,
        
        
        };
      });

    let handleCategoryModal1 = (type,id) =>{
   
        exportToExcel(transformedItems, 'instructore_attendance_report');

  
    }


    
    const searchCoursePrice = (e) =>{
        setSearchEntry(e?.target?.value)
      }

      
const disabledDate = (current) => {  
    return current && current > moment().endOf('day');
  }

  let handleFromDateChange = (e,type)=>{

    setFromDate(e); 
      setStartFromDate(e)
      form.setFieldsValue({ fromDate: e })   
  }

  let handleToDateChange = (e,type)=>{

    setToDate(e); 
      setStartToDate(e)
      form.setFieldsValue({ toDate: e })   
  }

  let studentViewDetails = () =>{

//   console.log(toDate)
//   console.log(moment(startToDate).format("YYYY-MM-DD") )


if(toDate == null || fromDate == null || selectedBatch == null && selectedStudent == null ){
    openNotification("Please check mandatory fields")
}else if(toDate == null || fromDate == null || selectedBatch == null && selectedBatch == null){
    openNotification("Please check mandatory fields")
}

else {
    openNotification("null")

    const dateFromOnly = new Date(fromDate).toLocaleDateString("en-CA"); // "en-CA" for "YYYY-MM-DD"
    const dateToOnly = new Date(toDate).toLocaleDateString("en-CA"); // "en-CA" for "YYYY-MM-DD"

    let payload = {
        "operation_type":"instructor_attendance_reports",
        "batch_id": selectedBatch.toString(),
        "instructor_id":selectedStudent == null ?"":selectedStudent.toString(),
        "from_date":dateFromOnly,
        "to_date":dateToOnly ,
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    dispatch(retrieveAllInstructorAttendanceDetails(payload))

}



}

const openNotification = (status) => {

    if(status == "null"){

    }else{
        api.info({
            description:
            `${status}`,
    
          });
    }

  
  
    };

let studentClearDetails = () =>{

    setStartFromDate("");
    setStartToDate("");
    setFromDate("");
    setToDate("");

    setSelectedBatch(null)
    setSelectedStudent(null)


    form.setFieldsValue({ toDate: null });
    form.setFieldsValue({ fromDate: null });
    form.setFieldsValue({ batch_id: null });
    form.setFieldsValue({ student_id: null });

    let payload = {
        "operation_type":"instructor_attendance_reports",
        "batch_id": "",
        "instructor_id":"",
        "from_date":"",
        "to_date":"",
        "tenant_id":window.localStorage.getItem("tenant_Id")
    }
    dispatch(retrieveAllInstructorAttendanceDetails(payload))


}

let handleAllBatchesChange = (e,type) =>{
    setSelectedBatch(e)
    let payload = {
        "operation_type":"instructor_detail_retrieve",
        "tenant_id": window.localStorage.getItem("tenant_Id"),
        "batch_id":e
    }

    dispatch(retrieveAllInstructorDetails(payload))
  }

  let handleAllStudentChange = (e,type) =>{
 
    setSelectedStudent(e)

  }


    const onFinish = (values) => {

  
    }; //

    const validateDates = () => {
        const values = form.getFieldsValue();
        const fromDate = values.fromDate;
        const toDate = values.toDate;
    
        if (!fromDate || !toDate) {
          message.error('Both From Date and To Date are required.');
          return;
        }
    
        if (fromDate.isAfter(toDate)) {
          message.error('From Date cannot be after To Date.');
        } else {
          message.success('Dates are valid!');
        }
      };
    
    return (
        <div>
 {contextHolder}
           
 <div style={{display:'flex' ,justifyContent:'space-between'}}>
        
            <div style={{display:'flex' ,justifyContent:'flex-start',marginBottom:'10px'}}>
            
               </div>

                <div style={{display:'flex' ,justifyContent:'flex-end',marginBottom:'10px'}}>




                <div className='alignRight'>
            <div className='searchBatch alignRight' style={{width:"200px",height:"20px"}}>
                          <InputComponent onChange={(e)=>searchCoursePrice(e)} 
                          className='searchField' placeholder={'Search'}/>
                          <img src={searchIcon} className="searchIconBatches" style={{height:"15px",width:"15px",marginTop:"8px"}}/>

                      </div>
           
                <div style={{marginTop:"10px" ,marginRight:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
            </div>
</div>

            </div>

            <div style={{display:'flex' ,justifyContent:'space-between'}}>
        
        <div style={{display:'flex' ,justifyContent:'flex-start',marginBottom:'10px'}}>

        <Form 
                  autoComplete="off"
                  layout="vertical"
                  form={form}
                  onFinish={validateDates} // Trigger validation on form submission
                  >




<Form.Item
            label="Batch"
            name="batch_id"
            style={{ width: "150px", maxHeight: "20px", height: "20px" }}
            rules={[
                {
                  required: true,
                  message: 'Batch is required',
                  
        
                },
              ]}
        >
            <Select
                name="batch_id"
                size="small"
                className="custom-select-height"
                onChange={(value) => handleAllBatchesChange(value)}
                value={selectedBatch} // Bind state to value
                allowClear // Adds a clear button within the dropdown
            >
                {allBatchesData && allBatchesData.data?.map((item, index) => (
                    <Select.Option key={index} value={item.batch_id}>
                        {item.batch_name}
                    </Select.Option>
                ))}
            </Select>
          
        </Form.Item>

</Form>

<div style={{marginLeft:"10px"}}></div>

<div style={{marginTop:"6px"}}>
<Form 
                  autoComplete="off"
                  layout="vertical"
                  form={form}>

<Form.Item
            label="Instructor"
            name="student_id"
            style={{ width: "150px", maxHeight: "20px", height: "20px" ,marginTop:"10px"}}
        >
            <Select
                name="student_id"
                size="small"
                className="custom-select-height"
                onChange={(value) => handleAllStudentChange(value)}
                value={selectedBatch} // Bind state to value
                allowClear // Adds a clear button within the dropdown
            >
                {allStudentData && allStudentData.data?.map((item, index) => (
                    <Select.Option key={index} value={item.instructor_id}>
                        {item.instructor_name}
                    </Select.Option>
                ))}
            </Select>
          
        </Form.Item>

</Form>
</div>



<div style={{marginLeft:"10px"}}></div>

        <Form 
                  autoComplete="off"
                  layout="vertical"
                  form={form}>
<Form.Item
    label="From Date"
    name="fromDate"
    // rules={[{ required: true, message: 'Please select from date' }]}
    style={{ width: "150px" }}
    rules={[
        {
          required: true,
          message: 'From date is required',
          

        },
        {
            validator: (_, value) => {
              if (value && toDate && value.isAfter(toDate)) {
                return Promise.reject('From Date cannot be after To Date');
              }
              return Promise.resolve();
            },
          },
      ]}
>
    <DatePicker
        name="fromDate"
        disabledDate={disabledDate}
        onChange={handleFromDateChange}
        value={fromDate}
        style={{ width: "150px" }} // Set width directly on DatePicker
    />
</Form.Item>
</Form>

<div style={{marginLeft:"10px"}}></div>

<Form 
                  autoComplete="off"
                  layout="vertical"
                  form={form} >
<Form.Item
    label="To Date"
    name="toDate"
    style={{ width: "150px" }}
    rules={[
        {
          required: true,
          message: 'To date is required',
          

        },
        {
            validator: (_, value) => {
              if (value && fromDate && value.isBefore(fromDate)) {
                return Promise.reject('To Date cannot be before From Date');
              }
              return Promise.resolve();
            },
          },
      ]}
>
    <DatePicker
        name="toDate"
        disabledDate={disabledDate}
        onChange={handleToDateChange}
        value={toDate}
        style={{ width: "150px" }} // Set width directly on DatePicker
    />
</Form.Item>
                    </Form>

 <div style={{marginLeft:"10px"}}></div>

 <Button type={"primary"} style={{width:"60px" ,marginTop:"30px"}}  className="submit" htmlType="Submit" onClick={studentViewDetails}>View</Button>

 <div style={{marginLeft:"10px"}}></div>

 <Button type={"primary"} style={{width:"60px" ,marginTop:"30px",backgroundColor: "red", borderColor: "red" }}  className="submit" htmlType="Submit" onClick={studentClearDetails}>Clear</Button>






           </div>

            <div style={{display:'flex' ,justifyContent:'flex-end',marginBottom:'10px'}}>




          
</div>

        </div>


             <TableComponent columns={columns} data={allStudentAttendanceDataList}/>
         </div>
    );
}

export default InStructoreAttendanceReports;
