import React, { useState } from 'react';
import { Tabs } from 'antd';
import StudentReports from '../studentReports/studentReports';
import EnrolledReports from '../enrolledReports/enrolledReports';
import ActiveUserReports from '../activeUserReports/activeUserReports';
import StudentAttendanceReports from '../studentAttendance/studentAttendanceDetails';
import InStructoreAttendanceReports from '../studentAttendance/instructoreAttendanceDetails';
import './tabController.css';

const { TabPane } = Tabs;

const ReportTabController = () => {
    const [activeKey, setActiveKey] = useState("2"); // State to track active tab

    const onTabChange = (key) => {
        setActiveKey(key); // Update state on tab change
    };

    return (
        <div className='reportTabContainer'>
            <div className='reportHeader'>
                <h3>Reports</h3>
            </div>

            <Tabs
              tabBarGutter={10}
              unselectable='off'
              
                activeKey={activeKey} // Controlled component
                onChange={onTabChange} // Handle tab change
                centered
                tabBarStyle={{ borderRadius: '10px',              border: '1px solid #1890ff', 
                  marginBottom: "10px", 
                  marginLeft: "0", 
                  width:'720px',
                   overflow: 'hidden',
                  display: 'flex', justifyContent: 'center' ,
                  marginRight: "0px", 
                
                }}
                className="customTabs"
            >
                <TabPane tab="Student Reports" key="2">
                    <StudentReports />
                </TabPane>

                <TabPane tab="Enrollment Reports" key="3">
                    <EnrolledReports />
                </TabPane>

                <TabPane tab="Active User Reports" key="4">
                    <ActiveUserReports />
                </TabPane>

                <TabPane tab="Student Attendance" key="5">
                    <StudentAttendanceReports />
                </TabPane>

                <TabPane tab="Instructor Attendance" key="6">
                    <InStructoreAttendanceReports />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default ReportTabController;
