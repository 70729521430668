import React from 'react'

import './publishCourse.css'
import { InputComponent, InputSelect, TextAreaComponent } from '../../../../commonComponents'
import MultiSelect from '../../../../commonComponents/multiSelect/MultiSelect'
let PublishCourse=(props)=> {
  console.log(props.publishCourseDetails.is_active,"checkInstructors")
  return (
    <div className='publishCourse'>
        <div><b>Message</b></div>

        <div className='message'>
            <div>
              <div><label className='labelName'>Welcome Message </label></div>
              <TextAreaComponent  className={"welcomeTextArea"} value={props.publishCourseDetails.welcomeMsgText} rows={10} name={'welcomeMsgText'}
               onChange={(e)=>props.handlePublishChange(e,{name: 'welcomeMsgText' })}/>
          <p className='text-danger'>{props.publishDetailError?.welcomeMsgError ? props.publishDetailError.welcomeMsgError  : "" }</p>
            </div>

            <div>
              <div><label className='labelName'>Congratulations Message</label></div>
                <TextAreaComponent 
                 className={"welcomeTextArea"} value={props.publishCourseDetails.congratulationsMsgText} rows={10} name={'congratulationsMsgText'}
                onChange={(e)=>props.handlePublishChange(e,{name: 'congratulationsMsgText'})}/>
          <p className='text-danger'>{props.publishDetailError?.congratulationsMsgError ? props.publishDetailError.congratulationsMsgError  : "" }</p>

            </div>
        </div>


      {props.modalType == "Edit Course" ?
        <div className='status'>
          <div className='filterInstructor'>
            <label>Status</label>
            <InputSelect
            defaultValue={props.publishCourseDetails.is_active == false ? "Inactive" : "Active"}
                                  
            //  defaultValue={props.publishCourseDetails.is_active ? props.publishCourseDetails.is_active: " Select"}
          name={"is_active"}
          //  value={props.publishCourseDetails.is_active ? props.publishCourseDetails.is_active: " "}
          onChange={(e)=>props.handleStatusChange(e,{name: 'is_active'})}
          options={[
           
              {
                value: true,
                label: 'Active',
              },
              {
                value: false,
                label: 'Inactive',
              },
          
          ]}/>
        </div>
        </div>
        :""}
    {/* {props.modalType == "Edit Course" ? 
        <div className='ins'>
          <b>Add Instructor (02)</b>
          <div className='filterInstructor'>
            <MultiSelect defaultValue={""}
          name={"addInstructor"} value={props.publishCourseDetails.addInstructor ? props.publishCourseDetails.addInstructor : " "}
          onChange={(e)=>props.handleMultipleInstructor(e,{name: 'addInstructor'})}
          options={props.courseInstructors}/>
        </div>
        </div>
        :""} */}
    </div>
  )
}

export default PublishCourse