import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PopOverComponent, TableComponent,InputComponent } from '../../../commonComponents';
import { changeCancelClassStatus, retrieveCancelledClasses ,cancelClassNotificationToStudents,cancelClassRejectedNotification} from '../../../redux/instructorCancelClass/action';
import {insertFireBaseChartDetails} from '../../../redux/studentLogin/action';
import { course,edit,excel,plus,upload,deleteIcon,searchIcon,TableEyeIcon } from "../../../images";
import moment from 'moment';
import { NavLink, Outlet } from "react-router-dom";
import * as XLSX from 'xlsx';
import ExportToExcel from '../../../commonComponents/XlsxFile/ExportToExcel'




const AdminCancelClass = () => {

  let dispatch = useDispatch();
  let [instructorCancelClass,setInstructorCancelClass] = useState();
  let [studentsClassCancelNotification,setStudentsClassCancelNotification] = useState();
  let [batchName,setBatchName] = useState();
  let [students,setStudents] = useState();
  let[searchEntry,setSearchEntry] = useState("");

  let allInstructorCancelledClass = useSelector((state)=>(state.instructorCancelClassReducer?.getAllInstructorCanceledClass));
  let cancelFcmDetailsForStudnets = useSelector((state)=>(state.instructorCancelClassReducer?.cancelClassStudentNotification));
  let cancelFcmDetailsRejected = useSelector((state)=>(state.instructorCancelClassReducer?.cancelClassRejectedd));
  const [popoverVisible, setPopoverVisible] = useState(false);

  useEffect(()=>{
    dispatch(retrieveCancelledClasses())
    // dispatch(cancelClassNotificationToStudents())
  },[])

  useEffect(()=>{
    setInstructorCancelClass(allInstructorCancelledClass?.data)
  },[allInstructorCancelledClass])


  useEffect(()=>{
    setStudentsClassCancelNotification()
    setStudentsClassCancelNotification(cancelFcmDetailsForStudnets)

    if(cancelFcmDetailsForStudnets){

      const fcmToken = cancelFcmDetailsForStudnets?.data&&Object.values(cancelFcmDetailsForStudnets?.data).map(item => item.fcmtoken);
  
      const usernames = cancelFcmDetailsForStudnets?.data&&Object.values(cancelFcmDetailsForStudnets?.data).map(item => item.username).join(',');
      
  
      

    let payloadFireBase = {
      "sender_name": window.localStorage.getItem("adminEmail"),
      "receiver_name":usernames,
      "peer_name": batchName,
      "peer_chat_type": "class cancel",//group chat
      "input": "Approved",
      "firebase_tokens": fcmToken, 
      "msg_type": "insert",
      "peer_type": "insert"
    };
    dispatch(insertFireBaseChartDetails(payloadFireBase));

    }else{

    }

  },[cancelFcmDetailsForStudnets])


  useEffect(()=>{
    setStudentsClassCancelNotification()
    setStudentsClassCancelNotification(cancelFcmDetailsForStudnets)

    if(cancelFcmDetailsRejected){

      const fcmToken = cancelFcmDetailsRejected?.data&&Object.values(cancelFcmDetailsRejected?.data).map(item => item.fcmtoken);
  
      const usernames = cancelFcmDetailsRejected?.data&&Object.values(cancelFcmDetailsRejected?.data).map(item => item.username).join(',');
      
  

    let payloadFireBase = {
      "sender_name": window.localStorage.getItem("adminEmail"),
      "receiver_name":usernames,
      "peer_name": batchName,
      "peer_chat_type": "class cancel",//group chat
      "input": "Rejected",
      "firebase_tokens": fcmToken, 
      "msg_type": "insert",
      "peer_type": "insert"
    };
    dispatch(insertFireBaseChartDetails(payloadFireBase));

    }else{

    }

  },[cancelFcmDetailsRejected])

    let columns = [
        
        {
            title: 'Cancel ID',
            dataIndex: 'cancel_id',
            sorter: true,
            sorter: (a, b) => a.cancel_id - b.cancel_id,
            filteredValue: [searchEntry],
            onFilter: (value,record)=>{
              return String(record.instructor_name).toLowerCase().includes(value.toLowerCase())||
                     String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                     String(record.batch_name).toLowerCase().includes(value.toLowerCase())||
                     String(record.scheduled_date).toLowerCase().includes(value.toLowerCase())||
                     String(record.status).toLowerCase().includes(value.toLowerCase())||
                     String(record.reason).toLowerCase().includes(value.toLowerCase())||

                     String(record.timings).toLowerCase().includes(value.toLowerCase())
                   }
    
          },
          {
            title: 'Instructor Name',
            dataIndex: 'instructor_name',
            sorter: true,
            sorter: (a, b) => a.instructor_name .localeCompare(b.instructor_name),

          },
          {
            title: 'Course Name',
            dataIndex: 'course_name',
            sorter: true,
            sorter: (a, b) => a.course_name .localeCompare(b.course_name),

          },
          {
            title: 'Batch Name',
            dataIndex: 'batch_name',
            sorter: true,
            sorter: (a, b) => a.batch_name .localeCompare(b.batch_name),

          },
          {
            title: 'Scheduled Date',
            
            dataIndex: 'scheduled_date',
            sorter: true,
            sorter: (a, b) => a.scheduled_date .localeCompare(b.scheduled_date),
            render: (text, record) => moment(record.scheduled_date, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY'),

          },
          {
            title: 'Timings',
            dataIndex: 'cancel_class_timing',

            sorter: true,
            sorter: (a, b) => a.cancel_class_timing .localeCompare(b.cancel_class_timing),
            // render: (text, record) => moment(record.cancel_class_timing, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY'),

          
          },
          {
            title: 'Reason',
            dataIndex: 'reason',

            sorter: true,
            sorter: (a, b) => a.reason .localeCompare(b.reason),

          
          },
          {
            title: 'Status',
            dataIndex: 'status',
            sorter: true,
            sorter: (a, b) => b.status .localeCompare(a.status),

            render: (text, record) => (
              
              <div>

<div className="actionIconsReschedule">
       

        {record.status === 'pending' ? (
      <div className="actionIconsReschedule">
                        <span className={record.status == "approved" ? "approveReScheduele" : record.status == "rejected" ? " rejectReScheduele" : "pedingSchedule"}>{record.status.toUpperCase()}</span>

        <PopOverComponent
          element={img}
          trigger="click"
          popUp={popUpDataOriginal(record.cancel_id,record.batch_id,record.batch_name,record.scheduled_date)}
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
        />
      </div>
    ):  <span className={record.status === "approved" ? "approveReScheduele" : record.status === "rejected" ? "rejectReScheduele" : "pendingSchedule"}>
    {record.status.toUpperCase()}
  </span>  }
       
      </div>
   
  </div>
             
             ),
          },
    ]

    let img = [
      <div className='statusClick'>
                  <span className='dotPopUp'></span>
                  <span className='dotPopUp'></span>
                  <span className='dotPopUp'></span>
    </div>
    ]

    const handleApprove = (id,batch_id,batchNameDetails,cancel_id) =>{

      setBatchName(batchNameDetails)

      let payload={
        "operation_type":"update",
        "op_field_details":{
              "cancel_id":id,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "status":"approved"
        }
     }
     dispatch(changeCancelClassStatus(payload))


     let payload1={
      "notification_type":"cancel_class_notification",
    "project_name":"lms",
    "batch_id":batch_id,
    "tenant_id":window.localStorage.getItem("tenant_Id"),
    "cancel_id":id

   }
   dispatch(cancelClassNotificationToStudents(payload1))
   setPopoverVisible(false);
    }
  
    const hanldeReject = (id,batch_id,batchNameDetails,date) =>{

      setBatchName(batchNameDetails)

      let payload1={
        "operation_type":"update",
        "op_field_details":{
              "cancel_id":id,
              "tenant_id":window.localStorage.getItem("tenant_Id"),
              "status":"rejected"
        }
     }
     dispatch(changeCancelClassStatus(payload1))

    let payload={
      "notification_type":"cancel_class_rejection_notification",
    "project_name":"lms",
    "batch_id":batch_id,
    "tenant_id":window.localStorage.getItem("tenant_Id"),
    "cancelled_date":date,
    "cancel_id":id
   }
   dispatch(cancelClassRejectedNotification(payload))

   setPopoverVisible(false);


  
    }
  
    const popUpDataOriginal = (reScheduleId,batchID,batchNameDetails,date) =>{
        return(
          <div className='popUpContainer'>
 <NavLink  title='Approve' className={'menutitle'}>

          <p style={{color:"black"}} onClick={()=>handleApprove(reScheduleId,batchID,batchNameDetails)} className='approveClick'>Approve</p>
          </NavLink>
          <NavLink  title='Approve' className={'menutitle'}>

          <p style={{color:"black"}} onClick={()=>hanldeReject(reScheduleId,batchID,batchNameDetails,date)} className='approveClick'>Reject</p>
          </NavLink>
      </div>
        )
    }


    const searchStudentByEmail = (e) =>{
      let data = e.target.value;
  setSearchEntry(data)
 
    }


    useEffect(()=>{
      if (searchEntry) {
        setStudents(allInstructorCancelledClass?.data)
      }
  },[searchEntry])

 



  const exportToExcel = (data, fileName) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  };
  
  
  const transformedItems = instructorCancelClass?.map((item) => {
   
  
    return { 
       CANCEL_ID: item.cancel_id,
       INSTRUCTOR_NAME:  item.instructor_name,
      COURSE_NAME:  item.course_name,
      BATCH_NAME: item.batch_name,
      SCHEDULE_DATE:  item.scheduled_date,
     CANCEL_CLASS_TIME:  item.cancel_class_timing,
    
     REASON:  item.reason,
     STATUS: item.status == 'pending' ? 'Pending' :
     item.status == 'approved' ? 'Approved' : 'Rejected',
    };
  });
  
  let handleCategoryModal1 = (type,id) =>{
     
    exportToExcel(transformedItems, 'cancel_class');
  
    
  }

    return (
        <div>

<div className="allStudentsHeader">

<div><h3 className="allStudents"></h3></div>
{/* <div><InputSelect defaultValue={"Select"}/></div> */}

<div className="searchCourse">
    <div className="searchC"><InputComponent
     className='searchField' placeholder={'Search'}
     onChange={(e)=>searchStudentByEmail(e)}/></div>
    <img src={searchIcon} className="searchIconStudents"/>
</div>

<div style={{marginTop:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
</div>
            <TableComponent columns={columns} data={instructorCancelClass}/>
        </div>
    );
}

export default AdminCancelClass;
