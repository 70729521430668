import React, { useEffect, useState } from 'react';
import { deleteIcon, edit, plus, searchIcon ,excel} from '../../images';

import './coursePrice.css'
import { TableComponent,ModalComponent, InputComponent } from '../../commonComponents';
import AddCoursePrice from './addCoursePrice/addCoursePrice';
import { Form, notification } from 'antd';
import { getCourses } from '../../redux/allCourses/action';
import { useDispatch, useSelector } from 'react-redux';
import { type } from '@testing-library/user-event/dist/type';
import { createAdminCoursePrice, deleteAdminCoursePrice, retrieveAdminCoursePrices, updateAdminCoursePrice } from '../../redux/coursePrice/action';
import moment from 'moment';
import dayjs from 'dayjs';

import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'


const CoursePrice = () => {
  const [api, contextHolder] = notification.useNotification();

  let dispatch = useDispatch();
  let getAllCourses = useSelector((state)=>state.courses.courses);

  let allCoursePrices = useSelector((state)=>state?.AdminCoursePriceReducer?.adminCoursePrices);

  let coursePriceCrud = useSelector((state)=>state?.AdminCoursePriceReducer?.adminCoursePriceCrudStatus)

  const [courseFeeId,setCourseFeeId] = useState("")
  const [allCourses,setAllCourses] = useState("");

  const [allAdminCoursePrices,setAllAdminCoursePrices] = useState("")

  const [courseId,setCourseId] = useState("");

  const [price,setPrice] = useState("")

  const [startDate,setStartDate] = useState("");

  const [endDate,setEndate] = useState("");

  const [modalType,setModalType] = useState("");
  const [form] = Form.useForm();

  const [feeModal,setFeeModal] = useState(false);

  const [searchEntry,setSearchEntry] = useState("");


  const customCompare = (a, b) => {
    if (a === null && b === null) {
      return 0;
    }
    if (a === null) {
      return 1; // Null values at the end
    }
    if (b === null) {
      return -1; // Null values at the beginning
    }
    // Compare non-null values
    return a - b; // Adjust this comparison based on your data type
  };

  useEffect(()=>{
    dispatch(getCourses())
  },[])

  useEffect(()=>{
    dispatch(retrieveAdminCoursePrices())
  },[])

  useEffect(()=>{
    setAllCourses(getAllCourses?.data)
  },[getAllCourses])


  useEffect(()=>{
    if (allCoursePrices) {
      setAllAdminCoursePrices(allCoursePrices)
      console.log(allCoursePrices,"pricess")
    }else{
      setAllAdminCoursePrices([])

    }
  },[allCoursePrices])


  useEffect(()=>{
      if (coursePriceCrud) {
        openNotification(coursePriceCrud?.msg,coursePriceCrud)
      }
  },[coursePriceCrud])

  const openNotification = (msg,response)=>{
    if (msg && response?.res_status== false) {
      api.info({
          description:
          `${msg}`,
        });
  }
  
  if (msg && response?.res_status == true) {
    api.info({
        description:
        `${msg}`,
      });
      setFeeModal(false)
  }
  }
  const handleModal = (type,id) =>{
    setStartDate("");
    setEndate("");
    setPrice("");
    setCourseId("");
    setCourseFeeId(id)
    form.setFieldsValue({["startDate"]:""});
    form.setFieldsValue({["endDate"]:""});
    form.setFieldsValue({["price"]:""});
    form.setFieldsValue({["courseId"]:""})
      setModalType(type);
      setFeeModal(true)
      if (type == "Edit Course Fee") {
        allAdminCoursePrices && allAdminCoursePrices.filter((item,index)=>{
          if (item.course_fees_id == id) {

           setCourseId(item?.course_id);
           setPrice(item?.course_fee);

           setStartDate(dayjs(item?.start_date,"DD-MM-YYYY"));

            if (item.end_date !=null) {
              setEndate(dayjs(item?.end_date,"DD-MM-YYYY"));
              form.setFieldsValue({["endDate"]:dayjs(item?.end_date,"DD-MM-YYYY")});
            }
           form.setFieldsValue({["startDate"]:dayjs(item?.start_date,"DD-MM-YYYY")});
          form.setFieldsValue({["price"]: item?.course_fee});
          form.setFieldsValue({["courseId"]:item?.course_id})

          }
        })
      }
  }

  const handleDeleteModal = (id)=>{
    let payload ={
      "operation_type": "delete",
      "op_field_details": {
          "course_fees_id": id
      }
  }
  dispatch(deleteAdminCoursePrice(payload))
  }

  const closeModal = ()=>{
    setFeeModal(false)
  }
  
  const handlePriceAndCourse = (e,type)=>{
    if (type.name == "price") {
      setPrice(e.target.value)
      form.setFieldsValue({[type.name]:e?.target?.value})
    }

    if (type.name == "courseId") {
      setCourseId(e)
      form.setFieldsValue({[type.name]:e})
    }
  }

  const handleDates = (e,type)=>{
    if (type.name == "startDate") {
      console.log(e,"start")
      setStartDate(e);
      form.setFieldsValue({[type.name]:e})
    }

    if (type.name == "endDate") {
      setEndate(e)
      form.setFieldsValue({[type.name]:e})
    }

  }

  const onFinish =()=>{
    console.log(courseId,price,startDate,endDate,"formValues")

    if (modalType == "Add Course Fee") {
      let payload =
      {
          "operation_type": "insert",
          "op_field_details": {
             "course_id": courseId,
            //  "course_name":"yoga",
             "start_date":startDate,
             "tenant_id":window.localStorage.getItem("tenant_Id"),
             "course_fee":price
          }
      }

      dispatch(createAdminCoursePrice(payload))
    }

    if (modalType != "Add Course Fee") {
      let payload ={
        "operation_type": "update",
        "op_field_details": {
            "course_fees_id":courseFeeId,
           "end_date": endDate
        }
    }
    dispatch(updateAdminCoursePrice(payload))
    }
  }

  const searchCoursePrice = (e) =>{
    setSearchEntry(e?.target?.value)
  }
    const columns = [
        
            {
                title: 'ID',
                dataIndex: 'course_fees_id',
                sorter: true,
                sorter: (a, b) => a.course_fees_id - b.course_fees_id,
                filteredValue: [searchEntry],
                onFilter: (value,record)=>{
                   return String(record.course_fees_id).toLowerCase().includes(value.toLowerCase())||
                          String(record.course_id).toLowerCase().includes(value.toLowerCase())||
                          String(record.course_fee).toLowerCase().includes(value.toLowerCase())||
                          String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                          String(record.start_date).toLowerCase().includes(value.toLowerCase())||
                          String(record.is_active).toLowerCase().includes(value.toLowerCase() === 'active'.toLowerCase() ? true : false )||
                          String(record.end_date).toLowerCase().includes(value.toLowerCase())
                        }

              },
              {
                title: 'Course ID',
                dataIndex: 'course_id',
                sorter: true,
                sorter: (a, b) => a.course_id - b.course_id,

              },


              {
                title: 'Price',
                dataIndex: 'course_fee',
                sorter: true,
                sorter: (a, b) => a.course_fee - b.course_fee,

              },
              {
                title: 'Course Name',
                dataIndex: 'course_name',
                sorter: true,
                sorter: (a, b) => a.course_name .localeCompare(b.course_name),

              },
              {
                title: 'Start Date',
                dataIndex: 'start_date',
                sorter: true,
                render: (text, record) => (
                  <div className="actionIcons">
                      <span>{(moment(record.start_date, "DD-MM-YYYY HH:mm:ss"))
                      ? (moment(record.start_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
                  </div>
                 ),
                 sorter: (a, b) => a.start_date .localeCompare(b.start_date),

              },
              {
                title: 'End Date',
                dataIndex: 'end_date',
                sorter: true,
                render: (text, record) => (
                  <div className="actionIcons">
                    {record.end_date !=null ? 
                      <span>{(moment(record.end_date, "DD-MM-YYYY HH:mm:ss"))
                      ? (moment(record.end_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
                      : ""}
                      </div>
                 ),
                  // sorter: (a, b) => customCompare(a.end_date-b.end_date),
                  sorter: (a, b) => {
                    if (a.end_date === null || b.end_date === null) {
                      return a.end_date === null ? -1 : 1; // Handle null values
                    }
                    return a.end_date.localeCompare(b.end_date); // Example sorting for non-null values
                  },

              },
              {
                title: 'Status',
                dataIndex: 'is_active',
                sorter: true,
                sorter: (a, b) => b.is_active - a.is_active,
    
                render: (text, record1) => (
                  <div className="actionIcons">
                    {record1.is_active == true ? 
                        <div className={"statusActive"}><span class="dotActive"></span> Active</div>
                              
                    : <div className={"statusInactive"}><span class="dotInactive"></span> Inactive</div>}
                      {/* <p className={record1.is_active == true ? "activeClass" :"inActiveClass"}>{record1.is_active == true ? "ACTIVE" : "INACTIVE"}</p> */}
                  </div>
                 ),
              },
              {
                title: 'Action',
                dataIndex: 'action',
                render: (text, record) => (
                  <div className="actionIcons">
                     <img src={edit} onClick={()=>handleModal("Edit Course Fee",record.course_fees_id)} />
                     <img src={deleteIcon} onClick={() => handleDeleteModal(record.course_fees_id)} />
                  </div>
                ),
              }
        ]

        const exportToExcel = (data, fileName) => {
          const ws = XLSX.utils.json_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
          XLSX.writeFile(wb, `${fileName}.xlsx`);
        };
        

        
        
       
        
        let handleCategoryModal1 = (type,id) =>{
          const transformedItems = allAdminCoursePrices?.map((item) => {
         
        
            return { 
               COURSE_FEE_ID: item.course_fees_id,
               COURSE_ID:  item.course_id,
              PRICE:  item.course_fee,
              COURSE_NAME: item.course_name,
              STAERT_DATE:  item.start_date,
             END_DATE:  item.end_date,
             STATUS:  item.is_active == true ? 'Active' : 'In Active',
  
            
            };
          });

          
          exportToExcel(transformedItems, 'course_fee');
        
          
        }

    return (
        <div className='coursePriceContainer'>
          {contextHolder}
             <div className='coursePrice'>
            <div>
            <h3>Course Fee</h3>
            </div>
            
            <div className='alignRight'>
            <div className='searchBatch alignRight'>
                          <InputComponent onChange={(e)=>searchCoursePrice(e)} 
                          className='searchField' placeholder={'Search'}/>
                          <img src={searchIcon} className="searchIconBatches" style={{marginBottom:'15px',}}/>

                      </div>
                <img src={plus} className='exportIcons' onClick={(e)=>handleModal("Add Course Fee","")}/>
           
                <div style={{marginTop:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
            </div>
        </div>
        <div>
        <TableComponent columns={columns} data={allAdminCoursePrices}/>

        </div>
        {feeModal ?
        <ModalComponent title={modalType}
        content={<AddCoursePrice
        courseId={courseId}
        allCourses={allCourses}
        price={price}
        startDate={startDate}
        endDate={endDate}
        handlePriceAndCourse={handlePriceAndCourse}
        handleDates={handleDates}
        onFinish={onFinish}
        modalType={modalType}
        form={form}
        closeModal={closeModal}/>}
          open={feeModal}
        closeModal={closeModal}
       />
        :""}
        </div>
       
    );
}

export default CoursePrice;
