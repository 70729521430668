import {GET_ALL_ADMIN_STUDENTS,STUDENT_CRUD_STATUS,RESET_STUDENT_ADMIN_PASSWORD,
    STUDENT_REQUEST_APPROVED_ACTION,STUDENT_REQUEST_REJECTED_ACTION,CONTACT_US_DETAILS
} from './actionTypes'

import axios from 'axios';
import apiConfig from '../../apiConfig/api';



export const getAllAdminStudents = (data) =>{
    return {
        type:GET_ALL_ADMIN_STUDENTS,
        payload: data
    }
}

export const studentCrudStatus = (data) =>{
    return {
        type:STUDENT_CRUD_STATUS,
        payload:data
    }
}


export const studenetRequestApprovedAction = (data) =>{
    return {
        type:STUDENT_REQUEST_APPROVED_ACTION,
        payload:data
    }
}

export const studenetRequestRejectedAction = (data) =>{
    return {
        type:STUDENT_REQUEST_REJECTED_ACTION,
        payload:data
    }
}


export const retrieveAllAdminStudents = ()=>{
    let payload = {
        "operation_type":"retrieve",
        "tenant_id": window.localStorage.getItem("tenant_Id")
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/student_enquiry',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(getAllAdminStudents(res?.data?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}


export const createAdminStudent = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/student_enquiry',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllAdminStudents())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const updateStudentAdmin = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/admin_student',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllAdminStudents())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const deleteAdminStudent = (studentId) =>{
    let payload ={
        "operation_type": "delete",
        "op_field_details":{
            "student_id": [studentId],
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/admin_student',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllAdminStudents())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const studentBulkActions = (payload)=>{
   
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/admin_student',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllAdminStudents())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const bulkAdminStudentDelete = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/admin_student',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllAdminStudents())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
} 

export const resetStudentAdminPassword = (payload)=>{
    console.log(payload,"payload")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/admin_student',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                // dispatch(retrieveAllAdminStudents())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}



export const studentRequestApproved = (payload)=>{
    console.log('',"payload")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/student_enquiry',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studenetRequestApprovedAction(res?.data))
                dispatch(retrieveAllAdminStudents())
            }

            if (res?.data?.res_status == false) {
                dispatch(studenetRequestApprovedAction(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}

export const studentRequestRejected = (payload)=>{
    console.log('',"payload")
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/student_enquiry',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studenetRequestRejectedAction(res?.data))
                dispatch(retrieveAllAdminStudents())
            }

            if (res?.data?.res_status == false) {
                dispatch(studenetRequestRejectedAction(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}
export const getContactUsDetails = (data) =>{
    return {
        type:CONTACT_US_DETAILS,
        payload: data
    }
}

export const retrieveAllContactUs = ()=>{
    let payload = {
        "operation_type":"retrieve",
        "tenant_id": window.localStorage.getItem("tenant_Id")
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+'e_lms/v1/contact_us',payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(getContactUsDetails(res?.data))
            }

            if (res?.data?.res_status == false) {
                dispatch(getContactUsDetails([]))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}





export const deleteContactUs = (studentId) =>{
    let payload ={
        "operation_type": "delete",
        "op_field_details":{
            "id": [studentId],
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
    }
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/contact_us",payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllContactUs())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}


export const updateContactUs = (studentId) =>{

    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/contact_us",studentId
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllContactUs())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}


export const bulkDeleteeleteContactUs = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/contact_us",payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllContactUs())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}


export const bulkUpdateContactUs = (payload) =>{
    return(dispatch)=>{
        axios.post("apiConfig.baseUrl+e_lms/v1/contact_us",payload
        ).then((res)=>{
            if (res?.data?.res_status == true) {
                dispatch(studentCrudStatus(res?.data))
                dispatch(retrieveAllContactUs())
            }

            if (res?.data?.res_status == false) {
                dispatch(studentCrudStatus(res?.data))
            }
          
        }).catch(err=>{
            console.log(err)
        })
}
}