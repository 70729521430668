



import { Button, DatePicker, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { InputComponent, InputSelect } from '../../../commonComponents';
import ReactDatePicker from 'react-datepicker';
import './addCourseFee.css';

const AddCourseFee = (props) => {
  const [courseDetails, setCourseDetails] = useState([]);
  const [batchDetails, setBatchDetails] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);

  useEffect(() => {
    if (props?.students) {
      const list3 = props.students.map((i) => ({
        value: i.student_id,
        label: i.fullname,
      }));
      setStudentDetails(list3);
    }
  }, [props?.students]);

  useEffect(() => {
    if (props?.courses) {
      const list = props.courses.map((i) => ({
        value: i.course_id,
        label: i.course_name,
      }));
      setCourseDetails(list);
    }
  }, [props?.courses]);

  useEffect(() => {
    if (props?.allBatches) {
      const list1 = props.allBatches.map((i) => ({
        value: i.batch_id,
        label: i.batch_name,
      }));
      setBatchDetails(list1);
    }
  }, [props?.allBatches]);

  const onlyNumbersValidator = (_, value) => {
    if (!value || /^\d+$/.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Please enter only numbers'));
  };

  return (
    <div className='addCourseFee'>
      <Form
        autoComplete="off"
        layout="vertical"
        form={props.form}
        onFinish={props.onFinish}
      >
        <div className='basicDetailsCourseFee'>
          <div className='firstNameCourseFee'>
            <Form.Item label="Student Name"
              name="student_id"
              rules={[
                { required: true, message: 'Please select student' },
              ]}>
              <div>
                <InputSelect options={studentDetails} 
                selected={props?.courseFeeDetails?.student_id}
                  value={props?.courseFeeDetails?.student_id}
                  defaultValue={props?.courseFeeDetails?.student_id}
                  onChange={(e) => props.handleChange(e, { name: "student_id" })} 


                  
                  />
              </div>
            </Form.Item>
          </div>
        </div>

        <div className='basicDetailsCourseFee'>
          <div className='firstNameCourseFee1'>
            <Form.Item label="Batch Name"
              name="batch_name"
              rules={[
                { required: true, message: 'Please select batch name' }
              ]}>
              <div>
                <InputSelect
                
                options={batchDetails}
                  value={props?.courseFeeDetails?.batch_name}
                  selected={props?.courseFeeDetails?.batch_name}
                  defaultValue={props?.courseFeeDetails?.batch_name}
                  onChange={(e) => props.handleChange(e, { name: "batch_name" })} 
                  

                  // value={props?.allBatches?.batch_name}
                  // defaultValue={props?.allBatches?.batch_name ? props?.allBatches?.batch_name : "select"}
                  // onChange={(e) => props.handleChange(e, { name: "batch_name" })}
                  
                  
                  />
              </div>
            </Form.Item>



          </div>
          <div className='firstNameCourseFee1'>
            <Form.Item label="Course Name"
              name="course_name"
              rules={[
                { required: true, message: 'Please select course name' }
              ]}>
              <div>
                <Select name={"course_name"} 
                value={props?.courseFeeDetails?.course_name}
                  defaultValue={props?.courseFeeDetails?.course_name ? props?.courseFeeDetails?.course_name : "select"}
                  onChange={(e) => props.handleChange(e, { name: "course_name" })}
                >
                  {courseDetails.map((item) => (
                    <Select.Option value={item.value} key={item.value}>{item.label}</Select.Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </div>
        </div>

        <div className='basicDetailsCourseFee'>
          <div className='firstNameCourseFee'>
            <Form.Item label="Course Fee"
              name="course_fee"
              rules={[
                { required: true, message: 'Please enter fee' },
                { validator: onlyNumbersValidator }
              ]}>
              <div>
                <InputComponent value={props.courseFeeDetails?.course_fee}
                  onChange={(e) => props.handleOnlyDigits(e, { name: "course_fee" })} />
              </div>
            </Form.Item>
          </div>

          <div className='firstNameCourseFee'>
            <Form.Item label="Course Discount"
              name="discount_rupees"
              rules={[
                { required: true, message: 'Please enter course discount!' },
                { validator: onlyNumbersValidator }
              ]}>
              <div>
                <InputComponent
                  value={props.courseFeeDetails?.discount_rupees}
                  onChange={(e) => props.handleOnlyDigits(e, { name: "discount_rupees" })} />
              </div>
            </Form.Item>
          </div>
        </div>

        <div className='basicDetailsCourseFee'>
          <div className='firstNameCourseFee'>
            <Form.Item label="Paid Amount"
              name="paid_amount"
              rules={[
                { required: true, message: 'Please enter paid amount' },
                { validator: onlyNumbersValidator }
              ]}>
              <div>
                <InputComponent value={props.courseFeeDetails?.paid_amount}
                  onChange={(e) => props.handleOnlyDigits(e, { name: "paid_amount" })} />
              </div>
            </Form.Item>
          </div>
          <div className='firstNameCourseFee'>
            <Form.Item label="Due Date"
              name="due_date"
              rules={[
                { required: true, message: 'Please select due date!' }
              ]}>
              <div className='datepickerCustomclass'>
                <div className='DueDate'>
                  <ReactDatePicker name="due_date"
                    mode='date'
                    selected={props?.courseFeeDetails?.due_date}
                    value={props?.courseFeeDetails?.due_date}
                    onChange={(e) => props.handleChange(e, { name: "due_date" })}
                  />
                </div>
              </div>
            </Form.Item>
          </div>
        </div>

        <Form.Item>
          <div className='CourseDetailsSubmit'>
            <Button type={"secondary"} className="cancel" onClick={props?.closeModal}>Cancel</Button>
            <div className='alignRight'>
              <Button htmlType='submit' primary type='primary'>Submit</Button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
}

export default AddCourseFee;
