import {GET_ACTIVE_USER_REPORTS,GET_STUDENT_ATTENDANCE,GET_ALL_BATCHES,GET_ALL_STUDENT,GET_ALL_STUDENT_ATTENDANCE,
    GET_ALL_INSTRUCTOR_ATTENDANCE,GET_ALL_INSTRUCTOR
} from'./actionTypes';

import axios from 'axios';
import apiConfig from '../../../apiConfig/api';

export const getActiveUserReports = (data)=>{
    return {
        type: GET_ACTIVE_USER_REPORTS,
        payload: data
    }
}

export const retireveActiveUserReports = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/reports",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getActiveUserReports(res?.data));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveStudentAttendanceDeails = (data)=>{
    return {
        type: GET_STUDENT_ATTENDANCE,
        payload: data
    }
}

export const retrieveStudentAttendance = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/class_checkin",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(getActiveUserReports(res?.data));
            }

            if (res.data?.res_status == false) {
                dispatch(getActiveUserReports([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const retrieveAllBatches = (data)=>{
    return {
        type: GET_ALL_BATCHES,
        payload: data
    }
}

export const retrieveAllBatchesDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
        ).then((res)=>{
            // console.log(res?.data?.data ,"AVNI")

            if (res.data?.res_status == true) {
                dispatch(retrieveAllBatches(res?.data));
            }

            if (res.data?.res_status == false) {
                dispatch(retrieveAllBatches([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveAllStudent = (data)=>{
    return {
        type: GET_ALL_STUDENT,
        payload: data
    }
}

export const retrieveAllStudentDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(retrieveAllStudent(res?.data));
            }

            if (res.data?.res_status == false) {
                dispatch(retrieveAllStudent([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveAllStudentAttendance = (data)=>{
    return {
        type: GET_ALL_STUDENT_ATTENDANCE,
        payload: data
    }
}

export const retrieveAllStudentAttendanceDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/class_checkin",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(retrieveAllStudentAttendance(res?.data));
            }

            if (res.data?.res_status == false) {
                dispatch(retrieveAllStudentAttendance([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}


export const retrieveAllInstructorAttendance = (data)=>{
    return {
        type: GET_ALL_INSTRUCTOR_ATTENDANCE,
        payload: data
    }
}

export const retrieveAllInstructorAttendanceDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/reports",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {

                console.log(res?.data)
                dispatch(retrieveAllInstructorAttendance(res?.data));
            }

            if (res.data?.res_status == false) {
                dispatch(retrieveAllInstructorAttendance([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const retrieveAllInstructor = (data)=>{
    return {
        type: GET_ALL_INSTRUCTOR,
        payload: data
    }
}

export const retrieveAllInstructorDetails = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
        ).then((res)=>{
            if (res.data?.res_status == true) {
                dispatch(retrieveAllInstructor(res?.data));
            }

            if (res.data?.res_status == false) {
                dispatch(retrieveAllInstructor([]));
            }
        }).catch(err=>{
            console.log(err)
        })
}
}