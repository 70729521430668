import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveStudentReports } from '../../../redux/adminReports/studentReports/action.js';

import { TableComponent,InputComponent } from '../../../commonComponents';
import { Table } from 'antd';
import moment from 'moment';
import { excel, plus, upload, searchIcon } from "../../../images";

import * as XLSX from 'xlsx';
import ExportToExcel from '../../../commonComponents/XlsxFile/ExportToExcel'
const StudentReports = (props) => {

    let dispatch = useDispatch()
    const [searchEntry,setSearchEntry] = useState("");


    const allStudentAdminReports = useSelector((state)=>state?.StudentReportsReducer?.studentsReportsData)

    const [adminStudentReports,setAdminStudentReports] = useState();
    useEffect(()=>{
        let payload = {
            "operation_type":"studentreports_view",
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveStudentReports(payload))
    },[])



    //studentReport
    useEffect(()=>{
        if (allStudentAdminReports) {
            // console.log(allStudentAdminReports?.data,"studentsReports")
            setAdminStudentReports(allStudentAdminReports?.data)
        }
    },[allStudentAdminReports])
//studentReport

const columns = [
  {
    title: 'Student ID',
    dataIndex: 'student_id',
    sorter: true,
    sorter: (a, b) => a.student_id - b.student_id,
    filteredValue: [searchEntry],
    onFilter: (value,record)=>{
       return String(record.student_id).toLowerCase().includes(value.toLowerCase())||
              String(record.student_name).toLowerCase().includes(value.toLowerCase())||
              String(record.enrolled_course).toLowerCase().includes(value.toLowerCase())||
              String(record.batch_start_date).toLowerCase().includes(value.toLowerCase())||
              String(record.batch_end_date).toLowerCase().includes(value.toLowerCase())||
              String(record.completed_courses).toLowerCase().includes(value.toLowerCase())
            }

  },
      {
        title: 'Student Name',
        dataIndex: 'student_name',
        sorter:true,
        sorter: (a, b) => a.student_name .localeCompare(b.student_name),
  
      },
      {
        title: 'Enrolled Course',
        dataIndex: 'enrolled_course',
        sorter:true,
        sorter: (a, b) => a.enrolled_course .localeCompare(b.enrolled_course),
  
      },
      {
        title: 'Start Date',
        dataIndex: 'batch_start_date',
        sorter:true,
        sorter: (a, b) => a.batch_start_date .localeCompare(b.batch_start_date),
        render: (text, record) => (
          <div className="actionIcons">
              <span>{(moment(record.batch_start_date, "DD-MM-YYYY HH:mm:ss"))
              ? (moment(record.batch_start_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
          </div>
         ),
  
      },
      {
        title: 'End Date',
        dataIndex: 'batch_end_date',
        sorter:true,
        sorter: (a, b) => a.batch_end_date .localeCompare(b.batch_end_date),
        render: (text, record) => (
          <div className="actionIcons">
              <span>{(moment(record.batch_end_date, "DD-MM-YYYY HH:mm:ss"))
              ? (moment(record.batch_end_date, "DD-MM-YYYY HH:mm:ss")).format("DD-MM-YYYY") : "" }</span>
          </div>
         ),
      },
      {
        title: 'Course Status',
        dataIndex: 'completed_courses',
        sorter:true,
        sorter: (a, b) => a.completed_courses .localeCompare(b.completed_courses),
  
      },
]






const exportToExcel = (data, fileName) => {
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
  XLSX.writeFile(wb, `${fileName}.xlsx`);
};


const transformedItems = adminStudentReports?.map((item) => {
 

  return { 
     STUDENT_ID: item.student_id,
     STUDENT_NAME:  item.student_name,
    ENROLLED_COURSE:  item.enrolled_course,
    BATCH_START_DATE: item.batch_start_date,
    BATCH_END_DATE:  item.batch_end_date,
   COMPLETED_COURSE:  item.completed_courses,
  
  
  };
});

let handleCategoryModal1 = (type,id) =>{
   
  exportToExcel(transformedItems, 'student_report');

  
}

const searchCoursePrice = (e) =>{
  setSearchEntry(e?.target?.value)
}


const quizData = [
  {
    question: "Which of the following is not a Java feature?",
    multiple_choice: true,
    multiple_choice_data: [
      { option: "Dynamic" },
      { option: "Architecture Neutral" },
      { option: "Use of pointers" },
      { option: "Object-oriented" },
    ]
  },
  {
    question: "_____ is used to find and fix bugs in the Java programs",
    multiple_choice: true,
    multiple_choice_data: [
      { option: "JVM" },
      { option: "JRE" },
      { option: "JDK" },
      { option: "JDB" },
    ]
  },
  {
    question: "Which of the following is a valid long literal?",
    multiple_choice: true,
    multiple_choice_data: [
      { option: "ABH8097" },
      { option: "L990023" },
      { option: "904423" },
      { option: "0xnf029L" },
    ]
  },
];

const [selectedOptions, setSelectedOptions] = useState({});
const [submittedData, setSubmittedData] = useState(null);

const handleOptionChange = (questionIndex, option) => {
  setSelectedOptions((prev) => ({
    ...prev,
    [questionIndex]: option,
  }));
};



const handleSubmit = () => {
  const results = quizData.map((item, questionIndex) => {
    return {
      question: item.question,
      multiple_choice: item.multiple_choice,
      multiple_choice_data: item.multiple_choice_data.map((optionData) => ({
        option: optionData.option,
        answer: optionData.answer,
        selected: selectedOptions[questionIndex] === optionData.option // Add selected property
      })),
    };
  });

  setSubmittedData(results);
  console.log("Submitted Data: ", results); // Log the submitted data
};
    return (
        <div>


{/* <h1>Java Quiz</h1>
      {quizData.map((item, questionIndex) => (
        <div key={questionIndex} className="question-container">
          <h3>
            {questionIndex + 1}. {item.question}
          </h3>
          {item.multiple_choice_data.map((optionData, optionIndex) => (
            <div key={optionIndex} className="option">
              <label>
                <input
                  type="radio"
                  name={`question-${questionIndex}`}
                  value={optionData.option}
                  checked={selectedOptions[questionIndex] === optionData.option}
                  onChange={() => handleOptionChange(questionIndex, optionData.option)}
                />
                {optionData.option}
              </label>
            </div>
          ))}
        </div>
      ))}



<button onClick={handleSubmit} style={{ marginTop: '20px' }}>
        Submit
      </button>

      {submittedData && (
        <div style={{ marginTop: '20px' }}>
          <h2>Submitted Data:</h2>
          <pre>{JSON.stringify(submittedData, null, 2)}</pre>
        </div>
      )}  */}












         <div style={{display:'flex' ,justifyContent:'flex-end',marginBottom:'10px'}}>

          <div className='alignRight'>
            <div className='searchBatch alignRight'>
                          <InputComponent onChange={(e)=>searchCoursePrice(e)} 
                          className='searchField' placeholder={'Search'}/>
                          <img src={searchIcon} className="searchIconBatches" style={{marginBottom:'15px',}}/>

                      </div>
           
                <div style={{marginTop:"10px" ,marginRight:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
            </div>


 </div>
          <TableComponent columns={columns} data={adminStudentReports}/> 



        </div>
    );
}

export default StudentReports;
