import React, { useEffect, useState } from 'react';

import './enrolledReports.css';
import { useDispatch, useSelector } from 'react-redux';
import { retrieveAdminEnrolledReports } from '../../../redux/adminReports/enrolledReports/action';
import { Table } from 'antd';
import { excel, plus, upload, searchIcon } from "../../../images";

import { TableComponent,InputComponent } from '../../../commonComponents';
import * as XLSX from 'xlsx';
import ExportToExcel from '../../../commonComponents/XlsxFile/ExportToExcel'
const EnrolledReports = () => {

    let dispatch = useDispatch()
    const [searchEntry,setSearchEntry] = useState("");

    const enrolledReports = useSelector((state)=>state.EnrolledReportsReducer?.enrolledReportsData)

    useEffect(()=>{
        let payload ={
            "operation_type":"enrollment_view",
            "tenant_id": window.localStorage.getItem("tenant_Id")
        }
        dispatch(retrieveAdminEnrolledReports(payload))
    },[])

    

    //enrolledReports
        useEffect(()=>{
            if (enrolledReports) {
                console.log(enrolledReports,"enrolled")
            }
        },[enrolledReports])

    //enrolledReports

    const columns = [
    
          
        {
            title: 'Student Name',
            dataIndex: 'student_name',
            sorter:true,
             sorter: (a, b) => a.student_name .localeCompare(b.student_name),
             filteredValue: [searchEntry],
             onFilter: (value,record)=>{
                return String(record.student_name).toLowerCase().includes(value.toLowerCase())||
                       String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                       String(record.course_completed).toLowerCase().includes(value.toLowerCase())
                     }
         
           },
          
          {
            title: 'Course',
            dataIndex: 'course_name',
            sorter:true,
            sorter: (a, b) => a.course_name .localeCompare(b.course_name),
      
          },
          {
            title: 'Course Status',
            dataIndex: 'course_completed',
            sorter:true,
            sorter: (a, b) => a.course_completed .localeCompare(b.course_completed),
      
          }
    ]


    const exportToExcel = (data, fileName) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      };
      
      
      const transformedItems = enrolledReports?.data?.map((item) => {
       
      
        return { 
           STUDENT_NAME: item.student_name,
           COURSE_NAME:  item.course_name,
          COURSE_COMPLETED:  item.course_completed,
       
        
        
        };
      });
    let handleCategoryModal1 = (type,id) =>{
   
        exportToExcel(transformedItems, 'enrollment_report');

  
    }

    const searchCoursePrice = (e) =>{
        setSearchEntry(e?.target?.value)
      }
    return (
        <div>
              <div style={{display:'flex' ,justifyContent:'flex-end',marginBottom:'10px'}}>

              <div className='alignRight'>
            <div className='searchBatch alignRight'>
                          <InputComponent onChange={(e)=>searchCoursePrice(e)} 
                          className='searchField' placeholder={'Search'}/>
                          <img src={searchIcon} className="searchIconBatches" style={{marginBottom:'15px',}}/>

                      </div>
           
                <div style={{marginTop:"10px" ,marginRight:"10px"}}>

<img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  />

</div>
            </div>

{/* <img src={excel} className="exportIcons" onClick={() => handleCategoryModal1("Add Category", null)}  /> */}

</div>
            <TableComponent columns={columns} data = {enrolledReports?.data}/>
        </div>
    );
}

export default EnrolledReports;
