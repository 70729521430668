import React from "react";

import moment from "moment";
import { Button, Form, TimePicker } from 'antd';


let StudentRegistrationDialog = (props) =>{
              
    return (
        <div className="retriveStudent">
                <div>



                <div>
                <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'white'}}></h>

                </div>

                    <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'black'}}>Student Registration</h>
                    <p>Your registration was successful and is waiting for the Student Admin to approve, Please check your email to activate your account.</p>
                  
            

                    <Form.Item>
                <div className='addTenantFooterBtns'>
                <div>
                <Button type={"secondary"}  className="cancel" ></Button>
                </div>
                <div className='alignRight'>
                <Button type={"primary"}  className="submit" htmlType="Submit" onClick={props?.handleSubMit}>OK</Button>
                </div>
                </div>
                
                </Form.Item>
                    

                </div>
        </div>
    )
}

export default StudentRegistrationDialog