import React, { useEffect, useState } from 'react'

import { InputComponent ,ButtonComponent} from '../../commonComponents'

import { Form ,notification} from 'antd';

import  './instructorProfile.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import InstructorPasswordReset from './instructorPasswordReset';
import {no_image} from '../../images';


import { retrieveInstructorDetails, uploadInstructorProfile } from '../../redux/instructorProfile/action';
let InstructorProfile=()=> {



    const [form] = Form.useForm();
    const [api, contextHolder] = notification.useNotification();

    let [changePassword,setChangePassword] = useState(false);
    let [notificationVisible,setNotificationVisible] = useState(false)

    let instructorDetails = useSelector((state)=>state.instructorProfileReducer?.instructorDetails?.data)

    let profileStatus = useSelector((state)=>state.instructorProfileReducer?.profileStatus)
    let dispatch = useDispatch();

    let navigate = useNavigate();


    let [profileValues,setProfileValues] = useState({
      instructor_first_name:"",
      instructor_last_name:"",
      email:"",
      phone_no:""
    })

    useEffect(()=>{
       let payload = {
        "operation_type":"view_instructor_data",
        "instructor_id": window.localStorage.getItem("instructor_id")
    }

    dispatch(retrieveInstructorDetails(payload))
    },[])

    useEffect(()=>{
      if (instructorDetails) {
        setProfileValues(instructorDetails[0]);
        console.log(profileValues,"data")
      }
    },[instructorDetails])

    useEffect(()=>{
        if (profileStatus == true) {
          if(notificationVisible == true){
            setProfileValues({
              uploadImage:""
          })
            openNotification(true)
            setNotificationVisible(false)


            let payload = {
              "operation_type":"view_instructor_data",
              "instructor_id": window.localStorage.getItem("instructor_id")
          }
      
          dispatch(retrieveInstructorDetails(payload))
          }            // alert("success")
            // navigate("/instructorDashboard")
        }

        if (profileStatus == false) {
            // alert("fail")
            if(notificationVisible == true){
              setProfileValues({
                profile_picture:""
            })
            openNotification(false)
              setNotificationVisible(false)
            }
        }
    },[profileStatus])


    const openNotification = (status) => {
      if (status == true) {
        api.info({
          // message: `Category ${details.course_category_name}`,
          description:
            'Profile updated Successfully',
        });
      }

      if (status == false) {
        api.info({
          // message: `Category ${details.course_category_name}`,
          description:
            'Invalid credentials',
        });
      }
  }


    let resetPassword = () =>{
        setChangePassword(!changePassword)
    }


    const convertToBase64 = (file) => {
        console.log(file,"abcsd")
          return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            if (file) {
              fileReader.readAsDataURL(file);
              fileReader.onload = () => {
                resolve(fileReader.result);
              };
            }
           
            fileReader.onerror = (error) => {
              reject(error);
            };
          });
        };
  
      let  onFileChange = async(event,type)=>{
        const file = event.target.files[0];
        const base64 = await convertToBase64(file);
        console.log(base64,"ppp")
        setProfileValues({ ...profileValues, profile_picture: base64});
          // decodeBase64(base64)
            console.log(profileValues,'regForm')
         }

    let onFinish = (formValues)=>{
        if (formValues) {
            console.log(formValues)

            let payload = {
                "operation_type": "update_profile_picture",
                "op_field_details":{
                "instructor_id": window.localStorage.getItem("instructor_id"),
                "profile_picture": profileValues.profile_picture
                }
            }
            dispatch(uploadInstructorProfile(payload))
            setNotificationVisible(true)
        }
    }

    const cancelResetPassword = () =>{
      navigate("/instructorDashboard")
    }
  return (
    <div className='profileContainer'>
       {contextHolder}
            <div className='profileHeader'>
                <div className=''>
                    <h3>Profile Setting</h3>
                </div>
                <div className='alignRight'>
                    <h3 onClick={()=>resetPassword()} className='underLine changePasswordStudent'>
                      {!changePassword? "Change Password?" : "Back to profile"}</h3>
                </div>
            </div>
            {!changePassword ?
             <Form 
            autoComplete="off"
            layout="vertical"
            form={form}
            onFinish={onFinish}>

                <div>
                    <Form.Item
                     label="First name"
                     name="first_name"
                     className='disabled'
                    
                     >
                        <div className='instructorProfileInput'>
                            <InputComponent disabled={true} value={profileValues?.instructor_first_name}/>
                        </div>
                    </Form.Item>
                </div>

                
                <div>
                    <Form.Item
                     label="Last name"
                     name="last_name"
                     className='disabled'
                    //  rules={[
                    //      {
                    //      required: true,
                    //      message: 'Last name is required',
 
                    //      },
                    //  ]}
                     >
                        <div className='instructorProfileInput'>
                            <InputComponent disabled={true} value={profileValues?.instructor_last_name}/>
                        </div>
                    </Form.Item>
                </div>

                <div>
                    <Form.Item
                     label="Email"
                     name="email"
                     className='disabled'
                    //  rules={[
                    //      {
                    //      required: true,
                    //      message: 'Email is required',
 
                    //      },
                    //  ]}
                     >
                        <div className='instructorProfileInput'>
                            <InputComponent disabled={true} value={profileValues?.email}/>
                        </div>
                    </Form.Item>
                </div>


                <div>
                    <Form.Item
                     label="Phone number"
                     name="phone_number"
                     className='disabled'
                    //  rules={[
                    //      {
                    //      required: true,
                    //      message: 'Phone number is required',
 
                    //      },
                    //  ]}
                     >
                        <div className='instructorProfileInput'>
                            <InputComponent disabled={true} value={profileValues.phone_no}/>
                        </div>
                    </Form.Item>
                </div>
                <div style={{marginTop:"10px"}}></div>

                <div className='profilePicture'>

                  
                <div>
  <img
    alt="noimage"
    src={profileValues?.profile_picture || no_image}
    width="200px"
    height="100px"
  />
</div>

                   
                   {/* <div>
                            <img alt='noimage' 
                            src={`${(profileValues?.profile_picture )}`}
                           width='200px' height={"100px"}/> 
                         </div> */}
                <div className='alignRight'>
                <InputComponent type="file" title={""} name={"uploadImage"}  style={{"display": "none"}}
                         onChange={(e)=>onFileChange(e,{name: "uploadImage"})}
                         className="profileUpload"/>
                </div>
                </div>

                <Form.Item>
              
              <div className="studentProfileSave">
              <ButtonComponent value={'Cancel'} onClick={()=>cancelResetPassword()} type={"dashed"}/>

              <ButtonComponent value={'SAVE'}  htmlType={'Submit'}type={"primary"}/>

                </div>
              </Form.Item>
            </Form>
            : <InstructorPasswordReset/>}
    </div>
  )
}

export default InstructorProfile