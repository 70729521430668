import React, { useEffect, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import {
  HashRouter,
  Routes,
  Route,
  useRoutes,useLocation, useNavigate,Navigate
} from "react-router-dom";
import { Provider, useSelector } from 'react-redux';

import  {store} from './redux/store'
import RegisterComponent from './components/Register/register';

import VerifyEmail from './components/Register/verifyEmail';

import InstructorVerifyEmail from './components/Instructor/Register/InstructorVerifyEmail';
import ConfirmPassword from './components/Register/confirmPassword';

import LayoutComponent from './components/Layout/layout';

import AllCourses from './components/AllCourses/allCourses';

import AllStudents from './components/AllStudents/allStudents';
import AllInstructors from './components/AllInstructors/allInstructors';
import Dashboard from './components/Dahboard/dashboard';
import CourseCategories from './components/CourseCategory/courseCategory';
import AllAnnouncements from './components/AllAnnouncements/allAnnouncements';
import Allbatches from './components/AllBatches/allbatches';

// import InstructorRegister from './components/Instructor';
import courseFee from './components/CourseFee/courseFee';

import CourseFee from './components/CourseFee/courseFee';
import AllSettings from './components/Settings/settings';
import rolesAndPermissions from './components/Roles and Permissions/rolesAndPermissions';
import Schedule from './components/Schedules/schedule';
import MessageComponent from './components/messages/messages';
import AllStudentCourses from './components/AllStudentCourses/allStudentCourses';
import PrivateRoute from './components/privateRoute';
import StudentDashboard from './components/studentDashboard/studentDashboard';

import StudentSchedules from './components/studentSchedules/studentSchedules';
import StudentAssignments from './components/studentAssignments/studentAssignments';

import StudentRegisteredCourses from './components/studentRegisteredCourses/studentRegisteredCourses'
import NoteFound from './components/NotFoundPage/noteFound';

import StudentSettings from './components/studentSettings/studentSettings';
import InstructorDashboard from './components/instructorDashboard/instructorDashboard';
import SingleCourse from './components/AllStudentCourses/singleStudentCourse/singleCourse';

import InstructorBatches from './components/instructorBatches/instructorBatches';
import IntructorStudents from './components/instructorStudents/intructorStudents';
import InstructorSchedules from './components/instructorSchedules/instructorSchedules';
import InstructorProfile from './components/instructorProfile/instructorProfile';
import ViewSingleBatch from './components/instructorDashboard/viewSingleBatch/viewSingleBatch';
import AdminLogin from './components/adminLogin/adminLogin';
import AdminRegistration from './components/adminLogin/adminRegistration/adminRegistration';
import AdminEmailVerify from './components/adminLogin/adminEmailVerify/adminEmailVerify';
import AdminPasswordConfirmation from './components/adminLogin/adminPasswordConfirmation/adminPasswordConfirmation';
import SuperAdminLogin from './components/superAdminLogin/superAdminLogin';

import superAdminDashboard from './components/superAdmin/superAdmindashboard';
import SuperAdminDashboard from './components/superAdmin/superAdmindashboard';
import TenentApproval from './components/superAdmin/tenentApproval/tenentApproval';
import TenentManagement from './components/superAdmin/tenentManagement/tenentManagement';
import TenantEnquiry from './components/tenantEnquiry/tenantEnquiry';
import ClassesInfo from './components/adminClassesInfo/classesInfo';
import AdminSchedule from './components/Schedules/schedule';
import ConfirmInstructorPassword from './components/Register/confirmInstructorPassword';
import CoursePrice from './components/coursePrice/coursePrice';
import AdminReports from './components/adminReports/adminReports';
import AllReports from './components/adminReports/All Reports/allReports';
import InstructorMessages from './components/instructorMessages/instructorMessages';
import AdminMessages from './components/adminMessages/adminMessages';
import { initializeApp } from 'firebase/app';
import ContactUsDetails from './components/contactUs/contactUsDetails';
import AdminDemoClassTab from './components/adminDemoClass/adminDemoClassTab';
import AdminProfileDetails from './components/adminProfile/adminProfileDetails';
import ReportTabController from './components/adminReports/reportTabController/reportTabControllerClass'
const root = ReactDOM.createRoot(document.getElementById('root'));


const Routing = ()=>{

  // const location = useLocation();
  // let navigate = useNavigate()
  let [roleType,setRoleType] = useState(window.localStorage.getItem("role_Type"));

   let checkRole = useSelector((state)=>state?.studentLoginReducer?.loginRole)

   let checkInstructorRole = useSelector((state)=>state?.instructorLoginReducer?.instructorLoginRole);

   let checkAdminRole = useSelector((state)=>state?.adminReducer?.adminLoginRole)

  useEffect(()=>{
    const fetchRoleType = async () => {
      let role = window.localStorage.getItem("role_Type");
      if (checkRole) {
        setRoleType(checkRole);
      }
    };
  
    fetchRoleType();
  },[checkRole])


  useEffect(()=>{
    if(checkInstructorRole){
      setRoleType(checkInstructorRole)
    }

  },[checkInstructorRole])

  useEffect(()=>{
    if (checkAdminRole) {
      setRoleType(checkAdminRole)
    }
  },[checkAdminRole])






  












  
  return (
  <>
  <Provider store={store}>
<HashRouter>
  <Routes>
    <Route path='/' element={<App/>}/>
    <Route path='/confirmPassword/:id' element={<ConfirmPassword/>}/>

    <Route path='/confirmInstructorPassword/:id' element={<ConfirmInstructorPassword/>}/>
    <Route path='/studentRegister' element={<RegisterComponent/>}/>
    <Route path='/verifyEmail' element={<VerifyEmail/>}/>
    <Route path='/instructorVerify' element={<InstructorVerifyEmail/>}/>

    <Route path="/tenantEnquiry" element={<TenantEnquiry/>}/>
    {/* <Route path="/tenantEnquiry" element={<div>TenantEnquiry</div>}/> */}

    <Route path='/tenantlogin' element={<AdminLogin/>}/>

    <Route path='/registration' element={<AdminRegistration/>}/>

    <Route path='/adminEmailVerify' element={<AdminEmailVerify/>}/>

    <Route path='/adminConfirmPassword' element={<AdminPasswordConfirmation/>}/>

    <Route path='/superadmin' element={<SuperAdminLogin/>}/>

    {/* <Route path='*' element={<NoteFound/>}/> */}

    <Route  element={<PrivateRoute/>}>
      <Route element={<LayoutComponent/>}>

           {/* adminRoutes*/}
           {roleType && roleType == "admin" ? 
           <>
           
           
          <Route path='/main' element={<Dashboard/>}/>
          <Route path='/allCourses' element={<AllCourses/>}/>
          <Route path='/allStudents' element={<AllStudents/>}/>
          <Route path='/allInstructors' element={<AllInstructors/>}/>
          <Route path='/courseCategory' element= {<CourseCategories/>}/>
          <Route path="/announcements" element={<AllAnnouncements/>}/>
          <Route path='/allbatches' element={<Allbatches/>}/>
          <Route path='/studentFee' element={<CourseFee/>}/>
          <Route path='/settings' element={<AllSettings/>}/>
          <Route path='/adminSchedules' element={<AdminSchedule/>}/>
          <Route path='/rolesandPermissions' element={<rolesAndPermissions/>}/>
          <Route path='/classesInfo' element={<ClassesInfo/>}/>
          {/* <Route path='/reports' element={<AdminReports/>}/> */}
          <Route path='/reports' element={<ReportTabController/>}/>

          <Route path='/courseFee' element={<CoursePrice/>}/>
          <Route path='/adminMessages' element={<InstructorMessages/>}/>//AdminMessages
          <Route path='/adminEnquiries' element={<ContactUsDetails/>}/>
          <Route path='/democlass' element={<AdminDemoClassTab/>}/>
          <Route  path='/adminProfile' element={<AdminProfileDetails/>}/>

          </>
          : ""}
          
          {/* adminRoutesEnd */}

          <>
          {/* {superAdminRoutes} */}
        {roleType && roleType == "superadmin" ? 
          <>
        <Route path='/superAdminDashboard' element={<SuperAdminDashboard/>}/>

        <Route path='/superadminTenantManagement' element={<TenentManagement/>}/>

        <Route path='/superadminTenantapproval' element={<TenentApproval/>}/>
        </>

        :  ""}
          {/* {superAdminRoutesEnd} */}
          </>
          

          {/* studentRoutes */}
          { roleType && roleType === "student" ? (
    <>
        <Route exact path='/studentDashboard' element={<StudentDashboard/>}/>
        <Route path="/studentMessages" element={<InstructorMessages />}/>//MessageComponent
        <Route path= "/studentAllcourses" element={<AllStudentCourses/>}/>
        <Route path='/studentRegisteredCourses' element={<StudentRegisteredCourses/>}/>
        <Route path='/studentSchedules' element={<StudentSchedules/>}/>
        <Route path='/studentAssignments' element={<StudentAssignments/>}/>
        <Route path='/studentProfile' element={<StudentSettings/>}/>
        <Route path='/studentViewCourse/:id' element={<SingleCourse/>}/>
    </>
    ) : (
       ""
    )}


          {roleType && roleType == "instructor" ? 
           <>
          <Route path='/instructorDashboard' element={<InstructorDashboard/>}/>
          <Route path='/instructorBatches' element={<InstructorBatches/>}/>
          <Route path='/instructorStudents' element={<IntructorStudents/>}/>
          <Route path='/instructorSchedules' element={<InstructorSchedules/>}/>
          <Route  path='/instructorProfile' element={<InstructorProfile/>}/>
          <Route path='/instructorViewBatch/:id' element={<ViewSingleBatch/>}/>
          <Route path='/instructorEarnings' element={<div>Earnings</div>}/>
          <Route path='/instructorMessages' element={<InstructorMessages/>}/>
          </>
          : ""}
         
            {/* {instructorRoutesEnd} */}
            
        </Route>
        </Route>
  </Routes>
  {/* privateRouteEnds */}
</HashRouter>
</Provider>
{/* <LayoutComponent/> */}
</>
  )
}

root.render(
  <Provider store={store}>
  <Routing/>
</Provider>
);

<script type="module" src="/firebase-messaging-sw.mjs"></script>

if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/firebase-messaging-sw.mjs').then(function(registration) {
      console.log('Service Worker registered with scope:', registration.scope);
    }).catch(function(err) {
      console.error('Service Worker registration failed:', err);
    });
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
