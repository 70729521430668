import React, { useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import { useState } from "react";
import { TableComponent,CheckboxComponent, 
          InputComponent, InputSelect,
          ModalComponent, ButtonComponent,ConfirmModalComponent } from "../../commonComponents";

import { course,edit,excel,plus,upload,deleteIcon,searchIcon } from "../../images";
import './allCourses.css';

import AddCourseModal from "./AddCourse/addCourse";
import CourseTypes from "../courseTypes/courseTypes";
import { Button, Pagination, notification } from "antd";
import { LoadingOutlined, SmileOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import AddCourseBasicDetails from "./AddCourse/addCourseBasicDetails/addCourseBasicDetails";
import AddCourseAdditionalDetails from "./AddCourse/addCourseAdditionalDetails/addCourseAdditionalDetails";
import PublishCourse from "./AddCourse/publishCourse/publishCourse";
import basicDetailsValidation from "./AddCourse/addCourseBasicDetails/basicDetailsValidation";
import courseAdditionalDetailsValidation from "./AddCourse/addCourseAdditionalDetails/courseAdditionalDetailsValidation";
import publishCourseValidation from "./AddCourse/publishCourse/publishCourseValidation";
import { adminCourseCrudStatuss, adminCoursesBulkActions, createCourse, deleteCourse, getCourses, updateCourse } from "../../redux/allCourses/action";
import {  getCategories, updateCategory } from "../../redux/allCategories/action";
import { getInstructorsForCategory } from "../../redux/allInstructors/action";
import * as XLSX from 'xlsx';
import ExportToExcel from '../../commonComponents/XlsxFile/ExportToExcel'
let AllCourses = () =>{

  const [selectedRowsKeys, setSelectedRowKeys] = useState(["189","185"]);
  const [selectedDropdown,setSelectedDropdown] = useState("Select")


  const rowSelection = {
    // selectedRowKeys: allInstructors && allInstructors.filter(item => item.is_active).map((item=>item.instructor_id)),
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (selectedRowsKeys.length !== 0) {
        setSelectedRowKeys([selected]);
      }


    },
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys([selectedRowKeys]);
    }
  };
  let dispatch = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  let [courseRecords,setCourseRecords] = useState();
  let [courseId,setCourseId] = useState();

  let [categoryDropdown,setCategoryDropdown] = useState();

  let [courseInstructors,setCourseInstructors] = useState()
  let getAllCourses = useSelector((state)=>state.courses.courses);

  let allCategories = useSelector((state)=> state.categories?.category?.data);

  let getInstructorsByCategory = useSelector((state)=>state.instructor?.instructors?.data);

  let courseSuccess = useSelector((state)=> state.courses?.course_success);

  let [pageSize,setPageSize] = useState(4);

  const [searchEntry,setSearchEntry] = useState("");

  console.log(courseSuccess,"success")
  
  console.log(getInstructorsByCategory,"fetch")

  let adminCourseCrudStatus = useSelector((state)=>state.courses?.adminCourseCrudStatus);

    useEffect(()=>{
      if (getAllCourses?.res_status == true) {
          setCourseRecords(getAllCourses.data)
      }else{
        setCourseRecords([])

      }
    },[getAllCourses])
  console.log(getAllCourses,"check")

  let bulkActions = [
    {label: "ACTIVE",value:true},
    {label: "INACTIVE",value:false},
    {label: "DELETE",value: "Delete"}
  ]


  const courseTypeValues = [
    {label:"ALL",value:getAllCourses?.data?.length},
    {label:"ACTIVE",value:getAllCourses?.data?.filter((item)=> item.is_active == true).length},
    {label:"INACTIVE",value:getAllCourses?.data?.filter((item)=> item.is_active == false).length},
    // {label:"TRASH",value:0},
    
  ]

  const [actionType,setActionType] = useState("");

  const handleCourseTypes = (type) =>{
    setActionType(type.target?.getAttribute("label"))
    if (type.target?.getAttribute("label") == "ACTIVE") {
       let array=[]
       getAllCourses?.data && getAllCourses?.data.filter((item,index)=>{
         if (item.is_active == true) {
          array.push(item)
         }
      })
      setCourseRecords(array)
      }

    if (type.target?.getAttribute("label") == "INACTIVE") {
      let array = []
      getAllCourses?.data && getAllCourses?.data.filter((item,index)=>{
        if (item.is_active == false) {
          array.push(item)
        }
     })
     setCourseRecords(array)
    }

    if (type.target?.getAttribute("label") == "ALL") {
      setCourseRecords(getAllCourses?.data)
    }
  }

  useEffect(()=>{
    dispatch(getCategories())
  },[])

  useEffect(()=>{
    if (allCategories) {
      setCategoryDropdown(allCategories);
    }
  },[allCategories])

  useEffect(()=>{
    let obj=[]

    if (getInstructorsByCategory) {
      getInstructorsByCategory && Object.values(getInstructorsByCategory).map((i,Index)=>{

       let newObj= {};
       newObj["value"] = i.instructor_course_category_map_id;
       newObj["label"] = i.instructor_first_name;
     obj.push(newObj)
     })
     setCourseInstructors(obj)

    }
  },[getInstructorsByCategory])
  let [isModalVisible,setIsModalVisible] = useState(false);

  let [isDeleteModalVisible,setIsDeleteModalVisile] = useState(false);

  let[modalType,setModalType] = useState("");
  
     let [courseDuration,setCourseDescription] = useState("");

     let [courseImage,setCourseImage] = useState()

     let [basicDetails,setBasicDetails] =  useState({
        courseName: '',
        courseCategory:'',
        courseTopic: '',
        courseLanguage: '',
        courseLevel: '',
        courseDuration: ''
     })

     let [categoryId,setCategoryId] = useState()

     let [basicDetailsError,setBasicDetailsError] = useState()

     //Additional Details

      let [additionalDetails,setAdditionalDetails] = useState({
        uploadImg: '',
        courseDescriptionText:'',
        course_teach:'',
        course_requirements:''
      })

    
     let [additionDetailError,setAdditionalDetailError] = useState();

     const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        if (file) {
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
        }
       
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    


  //  let decodeBase64=(base64data)=> {
  //     let base64ToString = Buffer.from(base64data, "base64").toString()
  //    setCourseImage({base64ToString });
  //    return base64ToString;
  // }
     let  onFileChange = async(event,type)=>{
      const file = event.target.files[0];
    const base64 = await convertToBase64(file);
    console.log(base64,"ppp")
    setAdditionalDetails({ ...additionalDetails, [type.name]: base64});
      // decodeBase64(base64)
        console.log(additionalDetails,'additional Details')
     }

     //publish Course

     let[publishCourseDetails,setPublishCourseDetails] = useState({
      welcomeMsgText: '',
      congratulationsMsgText: '',
      addInstructor:'',
      is_active: ''
     })

     let [publishDetailError,setPublishDetailsError] = useState()

     let handlebasicCourseDetails = (e,type)=>{
      if (e.target !==undefined) {
    const { name, value } = e.target;
    setBasicDetails({ ...basicDetails, [name]: value });
      }else {
        setBasicDetails({...basicDetails,[type.name]:e})
      }

      if (typeof(e) == Number) {
        alert(e)
        setBasicDetails({...basicDetails,courseCategory:e})
      }

    }

    let handleCategoryChange = (e,type) =>{
        setBasicDetails({...basicDetails,courseCategory:e})
        // setCategoryId(e)
    }

    useEffect((e,type)=>{
      
        setBasicDetails({...basicDetails,courseCategory:basicDetails.courseCategory})
        if (basicDetails.courseCategory) {
          let categoryIdPayload={
            "operation_type": "view_instructor",
            "course_category_id":basicDetails.courseCategory
        }
          dispatch(getInstructorsForCategory(categoryIdPayload))
        }
    },[basicDetails.courseCategory])


    let handleAdditionalDetails= (e,type) =>{
      if (e.target !==undefined) {
        const { name, value } = e.target;
        setAdditionalDetails({ ...additionalDetails, [name]: value });
       
          }else {
            setAdditionalDetails({...additionalDetails,[type.name]:e})
          }
          if ( type.name && type.name != '' || type.name == undefined) {
            setAdditionalDetails({...additionalDetails,[type.name]:e.target.value})
          }    
        }


    let handlePublishChange = (e,type)=>{
      //  console.log(e.target.textarea == undefined,type,"iii")
      if (e.target !==undefined) {
        const { name, value } = e.target;
        setPublishCourseDetails({ ...publishCourseDetails, [name]: value });
      }else {
            setPublishCourseDetails({...publishCourseDetails,[type.name]:e})
          }
        console.log(additionalDetails,'instructorFormVal')

        if (e.target.textarea != undefined) {
         
          setCourseDescription({courseDescriptionText:e.target.value})
        }

        if (type.name != '' || type.name == undefined) {
          setPublishCourseDetails({...publishCourseDetails,[type.name]:e.target.value})
        }

        // if (e.target !== undefined) {
        //   setAdditionalDetails({...publishCourseDetails,[type.name]: e})
        // }
    }

    let handleStatusChange = (e,type)=>{
      setPublishCourseDetails({...publishCourseDetails,is_active:e})
    }

  let [courseSelect,setCourseSelect] = useState(" ");

  // let [description,setDescription] = useState(" ")

  let [errors,setErrors] = useState({})

  const customSorter = (a, b) => {
    // Convert strings like "3 months" to numeric values for comparison
    const parseValue = (str) => {
      const regexResult = str.match(/\d+/);
      return regexResult ? parseInt(regexResult[0]) : 0;
    };

    // Extract numeric values from the strings
    const valueA = parseValue(a);
    const valueB = parseValue(b);

    return valueA - valueB;
  };

  const [current, setCurrent] = useState(0);
  
  const next = () => {
      console.log(basicDetails)
      let basicInformation = basicDetailsValidation(basicDetails)
      let {isValid,formErrors} = basicInformation
      if (formErrors) {
        setBasicDetailsError(formErrors)
      }

      if ( current == 0 && isValid == true) {

        setCurrent(current + 1);    
      }

      if (current == 1 ) {
        console.log(current)
        let additionDetail = courseAdditionalDetailsValidation(additionalDetails)
        let { additionalDetailsValid,detailsErrors} = additionDetail;
        if (detailsErrors) {
            setAdditionalDetailError(detailsErrors)
            console.log(detailsErrors)
        }
        if(additionalDetailsValid == true ){
          setCurrent(current+1)
        }
       }

      if (current ==2) {
        console.log(current)
      }

  };

  let handleStepperForm = () =>{
    let validationResult = publishCourseValidation(publishCourseDetails);

    let addAdditionalDetailResult = courseAdditionalDetailsValidation(additionalDetails);

    let basicInfo = basicDetailsValidation(basicDetails)
    console.log(basicInfo,addAdditionalDetailResult,validationResult)
    let {isPublish, publishDetailError} = validationResult;
    if (publishDetailError) {
      setPublishDetailsError(publishDetailError)
    }
    if (isPublish == true) {
        console.log(basicDetails,additionalDetails,publishCourseDetails)
        if (modalType == "Add Course") {
          let createPayload = 
          {
              "operation_type": "insert",
              "op_field_details": {
                  "course_name": basicDetails.courseName,
                  "course_category_id": basicDetails.courseCategory,
                  // "course_demo": "Y",
                  "course_thumbnail": additionalDetails.uploadImg,
                  "course_topic": basicDetails.courseTopic,
                  "course_description": additionalDetails.courseDescriptionText,
                  "course_language": basicDetails.courseLanguage,
                  "tenant_id": window.localStorage.getItem("tenant_Id"),
                  "course_duration": basicDetails.courseDuration,
                  "course_level": basicDetails.courseLevel,
                  "welcome_message": publishCourseDetails.welcomeMsgText,
                  "congratulations_message": publishCourseDetails.congratulationsMsgText,
                  "what_you_will_teach_the_course":additionalDetails.course_teach,
                  "course_requirements": additionalDetails.course_requirements
              }
          }
          dispatch(createCourse(createPayload));
          setIsModalVisible(false)
          setCurrent(0);
      setBasicDetails({ courseName: '',
      courseCategory:'',
      courseTopic: '',
      courseLanguage: '',
      courseLevel: '',
      courseDuration: ''});
      setAdditionalDetails({uploadImg: '',
      courseDescriptionText:'',
      course_teach:'',
      course_requirements:''});
      setPublishCourseDetails({ welcomeMsgText: '',
      congratulationsMsgText: '',
      addInstructor:'',
      is_active: ''});
      
        }
       

    }
      if( modalType == "Edit Course"){
        let editPayload ={
          "operation_type": "update",
          "op_field_details": {
              "course_id": courseId,
              "course_name": basicDetails.courseName,
              "course_category_id": basicDetails.courseCategory,
              "course_topic": basicDetails.courseTopic,
              "course_description": additionalDetails.courseDescriptionText,
              "course_language": basicDetails.courseLanguage,
              "tenant_id": window.localStorage.getItem("tenant_Id"),
              "is_active": publishCourseDetails.is_active,
              "course_duration": basicDetails.courseDuration,
              "course_level": basicDetails.courseLevel,
              "what_you_will_teach_the_course": additionalDetails.course_teach,
              "course_requirements": additionalDetails.course_requirements,
              "welcome_message": publishCourseDetails.welcomeMsgText,
              "congratulations_message": publishCourseDetails.congratulationsMsgText,
              "course_thumbnail": additionalDetails.uploadImg
          }
      }
      dispatch(updateCourse(editPayload))
      setIsModalVisible(false);
      setCurrent(0);
      setBasicDetails({ courseName: '',
      courseCategory:'',
      courseTopic: '',
      courseLanguage: '',
      courseLevel: '',
      courseDuration: ''});
      setAdditionalDetails({uploadImg: '',
      courseDescriptionText:'',
      course_teach:'',
      course_requirements:''});
      setPublishCourseDetails({ welcomeMsgText: '',
      congratulationsMsgText: '',
      addInstructor:'',
      is_active: ''});
      // setErrors({})
      // dispatch(getCourses())
      }
    
  }
  
  const prev = () => {
    setCurrent(current - 1);
  };
  
    useEffect(()=>{
      dispatch(getCourses())
    },[])

  let handleMultipleInstructor = (e)=>{
        setPublishCourseDetails({...publishCourseDetails,addInstructor:e})
        console.log(publishCourseDetails.addInstructor,"checkins")
  }

useEffect(()=>{
  if (adminCourseCrudStatus) {
    openNotification(adminCourseCrudStatus?.msg,adminCourseCrudStatus)
    console.log(adminCourseCrudStatus?.msg,adminCourseCrudStatus,"status")
    dispatch(adminCourseCrudStatuss(undefined))
  }

},[adminCourseCrudStatus])

const openNotification = (msg,adminCourseCrudStatus) => {
  if (msg && adminCourseCrudStatus?.res_status == true) {
    api.info({
      type: 'error',
      description:
        `${msg}`,
    });
  }
};


  const steps = [
    {
      title: 'Basic Information',
     
      icon: <SolutionOutlined />,
      content: <AddCourseBasicDetails basicDetails={basicDetails}
       categoryDropdown={categoryDropdown}
       handleCategoryChange = {handleCategoryChange}
      handlebasicCourseDetails={handlebasicCourseDetails}
       basicDetailsError = {basicDetailsError}/>
    },
    {
      title: 'Additional Details',
     
      icon: <SolutionOutlined />,
      content: <AddCourseAdditionalDetails additionalDetails={additionalDetails}
      handleAdditionalDetails = {handleAdditionalDetails} courseImage ={courseImage}
      onFileChange={onFileChange} additionDetailError={additionDetailError} modalType={modalType}
      />

    },
    {
      title: 'Publish Course',
     
      icon: <SolutionOutlined />,
      content : <PublishCourse publishCourseDetails={publishCourseDetails} modalType={modalType}
      handleStatusChange={handleStatusChange} courseInstructors={courseInstructors}
      handleMultipleInstructor={handleMultipleInstructor} 
       handlePublishChange={handlePublishChange} publishDetailError = {publishDetailError}/>
    },
  ];

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  // const rowSelection1 = {
  //   selectedRowKeys: selectedRowKeys,
  //   onSelectAll: (selected, selectedRows, changeRows) => {
  //     if (selectedRowKeys.length !== 0) {
  //       setSelectedRowKeys([selected]);
  //     }
  //   },
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedRowKeys(selectedRowKeys);
  //   }
  // };

  let handleModal = async(type,id) =>{
    setIsModalVisible(true)
    setIsDeleteModalVisile(false)
    setModalType(type)
    setCourseId(id)
    if (id !="") {
      courseRecords.filter((item,index)=>{
         if (item.course_id == id) {
          setBasicDetails({
            courseName : item.course_name,
            courseCategory: item.course_category_id,
            courseTopic: item.course_topic,
            courseLanguage: item.course_language,
            courseDuration: item.course_duration,
            courseLevel: item.course_level
          });

          setAdditionalDetails({
            courseDescriptionText : item.course_description,
            course_teach : item.what_you_will_teach_the_course, 
            course_requirements : item.course_requirements ,
            uploadImg : item.course_thumbnail

          });
          // convertToBase642(item.course_thumbnail)
          console.log(item.course_thumbnail,"checkImage")
          setCourseImage(item.course_thumbnail)
          setPublishCourseDetails({
            welcomeMsgText: item.welcome_message,
            congratulationsMsgText: item.congratulations_message,
            is_active: item.is_active
          })
         }
      })
    }
  }

  
  let handleCancel= ()=>{
    setIsModalVisible(false)
    setBasicDetails({courseName:"",courseCategory:"",courseLanguage: "",courseLevel:"",courseTopic:"",courseDuration:""});
    setAdditionalDetails({ courseDescriptionText:"",course_teach:"",course_requirements:""});
    setPublishCourseDetails({welcomeMsgText:"",congratulationsMsgText:""})
    setCurrent(0)
    setErrors({})
    dispatch(getCourses())
    setBasicDetailsError({})
    setAdditionalDetailError({})
    setPublishDetailsError({})
  }  


//   useEffect(()=>{
//     if (courseSelect) {
//       setCourseSelect(courseSelect)
//     }
//     if (description) {
//       setDescription(description)
//     }
//   },[courseSelect,description])

//   let handleChange=(e)=>{
//     setCourseSelect(e)
// }


// let handleDescription=(e)=>{
//   setDescription(e)
// }
  const handleOk = () => {
    let formErrors={
      courseError:"",
      descriptionError:""
    }
    // console.log(courseSelect.length,description,"check")
    if (courseSelect.trim().length <=1 ) {
      formErrors.courseError ="Please select course"
    }else  {
      formErrors.courseError = " "
    }
    // if (description.trim().length <=1) {
    //   formErrors.descriptionError = "Please enter description"
    // }else{
    //   formErrors.descriptionError = " ";
    // }
    setErrors(formErrors)

    if (formErrors.courseError == " " && formErrors.descriptionError == " ") {

      if (modalType =="Add Course") {
          setIsModalVisible(false)
          setCourseSelect( " ");
          // setDescription(" ")

      }else{
        setIsModalVisible(false)
        setCourseSelect( " ");
        // setDescription(" ")
      }
      
    }
  };
  
  let closeModal = () =>{
    setIsModalVisible(false)
    setErrors({})
  }

  //DeleteModal
  let handleDeleteModal = (id) =>{
    setIsDeleteModalVisile(true)
    setIsModalVisible(false)
    setCourseId(id)
   
  }

  let  closeDeleteModal = () =>{
    setIsDeleteModalVisile(false)
  }

  let handleDeleteCancel= ()=>{
    setIsDeleteModalVisile(false)
  } 

  const handleDeleteOk = () => {
    if (courseId !==null) {
      let deletePayload =  {
          "operation_type": "delete",
          "op_field_details": {
              "course_id": [courseId]
          }
      }
        dispatch(deleteCourse(deletePayload))
      }
    setIsDeleteModalVisile(false)
  };


  let onShowSizeChange = (pageSize,data)=>{
    alert()
    setPageSize(data)
  }

  // useEffect(() =>{
  //     if (searchEntry == "") {
  //       setCourseRecords(getAllCourses?.data)
  //     }
  // },[searchEntry])

  const searchCourseByName= (e) =>{
      let data = e.target.value;
      setSearchEntry(data)
  if (data == "") {
    setCourseRecords(getAllCourses?.data)
  }

  if (!data) return; // Handle empty search term
  const filteredArray = courseRecords.filter(record => 
    record.course_name.toLowerCase().includes(data.toLowerCase()) ||
    record.course_name.toUpperCase().includes(data.toUpperCase()) ||

    record.course_language.toLowerCase().includes(data.toLowerCase()) ||
    record.course_language.toUpperCase().includes(data.toUpperCase()) ||

    record.course_level.toLowerCase().includes(data.toLowerCase()))||

  
  setCourseRecords(filteredArray);
  }
  let footer=[
    <div className="addCourseBtn">
         

          {current < steps.length - 1 && (
          <ButtonComponent type="primary" className={"stepperSave"}
           onClick={next} value={" Save & Next"}>
            Next
          </ButtonComponent>
        )}
        {current === steps.length - 1 && (
          <ButtonComponent type="primary" value={"Submit"} onClick={handleStepperForm}>
            Submit
          </ButtonComponent>
        )}
        {current > 0 && (
          <ButtonComponent
            style={{
              margin: '0 8px',
            }}
            onClick={() =>prev()}
            value={"Previous"}
          >
          </ButtonComponent>
        )}
      </div>
  ]

  let deleteModalFooter = [
      <div className="editCourse">
        <div>
            {/* <CheckboxComponent/> */}
            <div>
          <Button type={"secondary"} default value={'Cancel'} className={'cancel ant-btn-default'} onClick={closeDeleteModal}>
            Cancel</Button>

          </div>
        </div>
        <div className="alignRight">
          {/* <div>
          <Button type={"secondary"} default value={'Cancel'} className={'cancel ant-btn-default'} onClick={closeDeleteModal}>
            Cancel</Button>

          </div> */}
          <div>
          <Button type={"primary"}  danger value={'Delete'} className={'submit'} onClick={handleDeleteOk}>Delete</Button>
            
          </div>
       
        </div>
      </div>
  ]


 
        

    const columns = [

      {
        title: 'ID',
        dataIndex: 'course_id',
        sorter: true,
        sorter: (a, b) => a.course_id - b.course_id,

      },

        {
          title: 'Name',
          dataIndex: 'course_name',
          sorter: true,
          sorter: (a, b) => a.course_name .localeCompare(b.course_name),

        },
       
          {
            title: 'Language',
            dataIndex: 'course_language',
            sorter: true,
            sorter: (a, b) => a.course_language .localeCompare(b.course_language),

            filteredValue: [searchEntry],
            onFilter: (value,record)=>{
               return String(record.course_language).toLowerCase().includes(value.toLowerCase())||
                      String(record.course_level).toLowerCase().includes(value.toLowerCase())||
                      String(record.course_duration).toLowerCase().includes(value.toLowerCase())||
                      String(record.course_name).toLowerCase().includes(value.toLowerCase())||
                      String(record.is_active).toLowerCase().includes(value.toLowerCase() === 'active'.toLowerCase() ? true : false )||
                      String(record.course_id).toLowerCase().includes(value.toLowerCase())
                    }
          },
        {
          title: 'Level',
          dataIndex: 'course_level',
          sorter: true,
          sorter: (a, b) => a.course_level .localeCompare(b.course_level),

        },
        {
          title: 'Duration',
          dataIndex: 'course_duration',
          sorter: true,
          // sorter: (a, b) => {
        //     console.log("Sorting:", a.course_duration, b.course_duration);
        //     return b.course_duration.localeCompare(a.course_duration);
        // }

        sorter: (a, b) => {
          // Extract numeric values from the duration strings
          const durationA = parseInt(a.course_duration);
          const durationB = parseInt(b.course_duration);
          
          // If durations are NaN (not a number), return 0 to maintain the original order
          if (isNaN(durationA) || isNaN(durationB)) {
              return 0;
          }
          
          // Compare numeric values for sorting
          return durationA - durationB;
      }

          //  sorter: (a, b) => a.course_duration .localeCompare(b.course_duration),
        // sorter:(a,b) => customSorter(a.course_duration,b.course_duration)
        },
        {
          title: 'Status',
          dataIndex: 'status',
          sorter: true,
          sorter: (a, b) => b.is_active - a.is_active,
          render: (text, record1) => (
            <div className="actionIcons">
                  {record1.is_active == true ? 
                        <div className={"statusActive"}><span class="dotActive"></span> Active</div>
                              
                    : <div className={"statusInactive"}><span class="dotInactive"></span> Inactive</div>}          </div>
          ),
        },
        {
          title:'Action',
          dataIndex: 'action',
          render: (text, record) => (
            <div className="actionIcons">
                <img src={edit} onClick={() => handleModal("Edit Course",record.course_id)}/>
                <img src={deleteIcon} onClick={()=> handleDeleteModal(record.course_id)}/>
            </div>
           ),
        }
      ];

      let onChangeBulkActions = (type) =>{
        console.log(type,"anil",selectedRowsKeys)
        if (type !=="Delete") {
          let payload ={
            "operation_type": "multiple_update",
            "op_field_details": {
                "course_id":selectedRowsKeys[0],
                "is_active": type
            }
        }
         dispatch(adminCoursesBulkActions(payload))
        // setSelectedDropdown("Select");
        }else{
          let deletePayload = {
            "operation_type": "delete",
            "op_field_details": {
                "course_id": selectedRowsKeys[0]
            }
        }
         dispatch(deleteCourse(deletePayload))
        }
        
      }

      const handlePageSizeChange = (e)=>{
        console.log(e,"hhh")
      }




      const exportToExcel = (data, fileName) => {
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${fileName}.xlsx`);
      };
      
      
      const transformedItems = courseRecords?.map((item) => {
       
      
        return { 
          COURSE_ID: item.course_id,
           COURSE_NAME:  item.course_name,
          LANGUAGE:  item.course_language,
          LEVEL: item.course_level,
          DURATION:  item.course_duration,
         STATUS:  item.is_active == true ? 'Active' :'In Active',
        
        
        
        };
      });
      
      let handleCategoryModal1 = (type,id) =>{
         
        exportToExcel(transformedItems, 'course');
      
        
      }
    return (
      <div className="allCoursesContainer">
        {contextHolder}
        <div className="allCourses">
            <h3>All Courses</h3>
            <div className="searchCourse">
                <div className="searchC"><InputComponent className='searchField'
                 placeholder={'Search'} onChange={(e)=>searchCourseByName(e)}/></div>
                <img src={searchIcon} className="searchIcon" style={{marginBottom:'45px',marginLeft:"10px"}}/>
            </div>
        </div>
        <div className="allCoursesSelections">

          
            <CourseTypes handleModal={(e)=>handleModal("Add Course","")}
            handleModal1={(e)=>handleCategoryModal1("","")}
            bulkActions={bulkActions} handleCourseTypes={handleCourseTypes}
            courseTypeValues={courseTypeValues} actionType={actionType}
            onChangeBulkActions= {onChangeBulkActions} selectedDropdown = {selectedDropdown}/>
        </div>
        <div className="courseTable">
            <TableComponent columns={columns} data={courseRecords} sorter={true}
               loading={!getAllCourses ? true : false }

             rowSelection={rowSelection} selectedRowKeys= {selectedRowsKeys} rowKey={"course_id"}
             onShowSizeChange={handlePageSizeChange}
        />
         {/* <Pagination
  onShowSizeChange={handlePageSize}
/> */}
        </div>
        <div className="allCourseModal">
        { isModalVisible ?
        
                  <ModalComponent title={modalType? modalType: " "} width={1000}  height={1000}
                   content={<AddCourseModal  steps={steps} current={current} next={next} prev={prev}
                    items={items} 
                    courseSelect={courseSelect}  errors={errors} />}
                   open={isModalVisible} handleOk={handleOk} closeModal={closeModal} footer={footer}
                  showModal={handleModal} handleCancel={handleCancel}/>
              : ""}  

        {isDeleteModalVisible ?
               <ModalComponent title={"Delete Course"} 
                  footer={deleteModalFooter} content={"Are you sure you want to delete this course?"}
                   open={isDeleteModalVisible} handleOk={handleDeleteOk} closeModal={closeDeleteModal}
                  showModal={handleDeleteModal} handleCancel={handleDeleteCancel}/>: ""}
        </div>
        </div>
    )
}

export default AllCourses