import apiConfig from '../../apiConfig/api';
import { COURSE_SUCCESS, GET_COURSES,SET_COURSES,ADMIN_COURSE_CRUD_STATUS ,ALL_BATCHES} from "./actionTypes";

import axios from 'axios';


const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

export const setCourses = (data)=>{
    return {
        type: SET_COURSES,
        payload: data
    }
}

export const getAllCourses = (data) =>{
    return {
        type: GET_COURSES,
        payload: data
    }
}

export const courseSuccess = (data)=>{
    return{
        type: COURSE_SUCCESS,
        payload: data
    }
}

export const adminCourseCrudStatuss = (data)=>{
    return {
        type: ADMIN_COURSE_CRUD_STATUS,
        payload:data
    }
}


 //get courses
 export const getCourses = () =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/courses",{"operation_type": "select","tenant_id":window.localStorage.getItem("tenant_Id")}
        ).then((res)=>{
            console.log(res.data.res_status,'hello')
            dispatch(getAllCourses(res?.data));

        }).catch(err=>{
            console.log(err)
        })
}
}

    //create course
    export const createCourse = (data) =>{
        return(dispatch)=>{
            axios.post(apiConfig.baseUrl+"e_lms/v1/courses",data, {headers}
            ).then((res)=>{
                console.log(res.data.res_status,'hello')
                dispatch(setCourses(res.data));
                if (res?.data.res_status == true) {
                    dispatch(courseSuccess(res?.data.res_status));
                    dispatch(adminCourseCrudStatuss(res?.data));
                    dispatch(getCourses())

                }
            }).catch(err=>{
                console.log(err)
            })
    }
    }


    // delete course

    export const deleteCourse = (payload) =>{
        return(dispatch)=>{
            axios.post(apiConfig.baseUrl+"e_lms/v1/courses",payload
            ).then((res)=>{
                console.log(res.data.res_status,'hello')
                if (res.data.res_status == true) {
                    dispatch(getCourses())
                    dispatch(courseSuccess(res?.data.res_status))
                    dispatch(adminCourseCrudStatuss(res?.data))

                }
    
            }).catch(err=>{
                console.log(err)
            })
    }
    }


    //Update course


    export const updateCourse = (data) =>{
        return(dispatch)=>{
            axios.post(apiConfig.baseUrl+"e_lms/v1/courses",data
            ).then((res)=>{
                if (res.data.res_status == true) {
                    dispatch(getCourses());
                    // dispatch(courseSuccess(res?.data.res_status))
                    dispatch(adminCourseCrudStatuss(res?.data))

                }
            }).catch(err=>{
                console.log(err)
            })
    }
    }


    //  courses bulkActions

export const adminCoursesBulkActions = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/courses",payload
        ).then((res)=>{
            console.log(res.data.res_status,'hello')
            dispatch(adminCourseCrudStatuss(res.data));
            dispatch(getCourses())

        }).catch(err=>{
            console.log(err)
        })
    }
}


export const getAllBatches = (data) =>{
    return {
        type: ALL_BATCHES,
        payload: data
    }
}


 //get courses
 export const getBatches = (payload) =>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/announcement",payload
        ).then((res)=>{
            console.log(res.data.res_status,'hello')

            if(res.data.res_status == true){
                dispatch(getAllBatches(res.data));

            }else{
                dispatch(getAllBatches());

            }

        }).catch(err=>{
            console.log(err)
        })
}
}