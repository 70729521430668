import React from "react";
import { InputComponent } from "../../commonComponents";

import { excel, plus, upload, searchIcon } from "../../images";
import './courseTypes.css';
import { Select } from "antd";

let ContactUsCourseTypes = (props) => {
    let bulkOptions = props.bulkActions
    let list =[]
    bulkOptions && bulkOptions.map((item,index)=>{
        let obj={}
        obj["label"]= item.label;
        obj["value"] = item.value
        list.push(obj)
    })
    console.log(props?.courseTypeValues);

    return (
        <>
        <>
        {props?.courseTypeValues ? 
        <><div className="courseRecords">
                        {/* <div className="record">ALL(45)</div>
    <div className="record">ACTIVE(20)</div>
    <div className="record">INACTIVE(20)</div>
    <div className="record">Trash(25)</div> */}
                        {props?.courseTypeValues && props?.courseTypeValues.map((item, index) => {
                            return (
                                <div className={props.actionType == item.label ? "record courseType" : "record"} label={item.label} onClick={(e) => props.handleCourseTypes(e)}>{item.label}({item.value})</div>
                            );
                        })}
                    </div><div className="bulkActions">
                            <Select defaultValue={props.selectedDropdown} value={props.selectedDropdown}
                                onChange={(e) => props?.onChangeBulkActions(e)}>
                                {props.bulkActions && props.bulkActions.map((item, index) => {
                                    return (<>
                                        <Select.Option value={item.value}>{item.label}</Select.Option>
                                    </>);
                                })}
                            </Select>
                        </div></>
            : ""}
           </>
            <div className="exportCourseIcons">
           

                <img src={excel} className="exportIcons" onClick={props.handleModal1} />
                {/* <img src={plus} className="exportIcons" onClick={props.handleModal} />
                <img src={upload} className="exportIcons" /> */}
            </div></>
    )
}


export default ContactUsCourseTypes;