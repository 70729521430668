import React from 'react';

import { Tabs, Switch } from 'antd';
import AdminCancelClass from './adminCancelClass/adminCancelClass';
import AdminRescheduleClass from './adminRescheduledClass/adminRescheduleClass';
import AdminScheduleClass from './adminScheduledClass/adminScheduleClass';



import './classesInfo.css';
import { InputComponent } from '../../commonComponents';
const { TabPane } = Tabs;





const ClassesInfo = () => {
    return (
        <div className='classesTabContainer'>
             <div className='classesInfoSearch alignRight'>
              <div><h3>Classes Info</h3></div>
              <div><h3></h3></div>
          </div>
            <div>
        <Tabs defaultActiveKey="1">
          {/* <TabPane tab="SCHEDULED CLASSES" key="1">
            <AdminScheduleClass/>
            
          </TabPane> */}
          <TabPane tab="RESCHEDULED CLASSES" key="2">
            <AdminRescheduleClass/>

          </TabPane>
          <TabPane tab="CANCELLED CLASSES" key="3">
            <AdminCancelClass/>
            
          </TabPane>
          
        </Tabs>
        </div>
       
        </div>
      );
}

export default ClassesInfo;
