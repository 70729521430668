import React from "react";

import moment from "moment";
import { Button, Form, TimePicker } from 'antd';


let PrivacyPolicyStudent = (props) =>{
              
    console.log(props.privacyPolicyData)

    console.log(props.privacyPolicyData.data[0]['privacy_policy'])

    return (
        <div className="retriveStudent">
                <div>



                <div>
                <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'white'}}></h>

                </div>

                    <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'black'}}>Privacy Policy</h>
                    <p>{props.privacyPolicyData.data[0]['privacy_policy']}</p>
                  
                    <h style={{ fontSize: '20px' ,fontWidget : 'bold',color:'black'}}>Terms And Conditions</h>
                    <p>{props.privacyPolicyData.data[0]['terms_and_condition']}</p>







                    <Form.Item>
                <div className='addTenantFooterBtns'>
                <div>
                <Button type={"secondary"}  className="cancel" ></Button>
                </div>
                <div className='alignRight'>
                <Button type={"primary"}  className="submit" htmlType="Submit" onClick={props?.handleSubMit}>Agree</Button>
                </div>
                </div>
                
                </Form.Item>
                    

                </div>
        </div>
    )
}

export default PrivacyPolicyStudent