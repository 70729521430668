import { SET_INSTRUCTOR_LOGIN,GET_INSTRUCTOR_LOGIN,SET_INSTRUCTOR_TOKEN,GET_INSTRUCTOR_MENUS
,GET_INSTRUCTOR_LOGIN_ROLE, 
SET_CURRENT_INSTRUCTOR_GROUPS,
SET_CURRENT_INSTRUCTOR_GROUP_INFORMATION,
INSTRUCTOR_INSERT_GROUP_CHART,
INSTRUCTOR_INSERT_GROUP_CHART_FIRE_BASE,
FETCH_INSTRUCTOR_MESSAGES,
RETRIEVE_GROUP_CHART_DETAILS,INSTRUCTORE_CONTCAT_US
} from "./actionTypes";

import axios from "axios";
import apiConfig from '../../apiConfig/api';


import { getMessaging, getToken ,onMessage} from "firebase/messaging";

import { initializeApp } from "firebase/app";
import { type } from "@testing-library/user-event/dist/type";
import { getFirebaseToken } from "../../firebase";

const headers = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
    };

    const firebaseConfig = {
       
        apiKey:    "AIzaSyAELDRetplfLWTx0VPUIGQWm87tQaNHdlY",
        authDomain: "e-learning-67962.firebaseapp.com",
        projectId: "e-learning-67962",
        storageBucket: "e-learning-67962.appspot.com",
        messagingSenderId: "742660303601",
        appId: "1:742660303601:web:d48476fedb12f2739b5750",
        measurementId: "G-52QYLN2K92"
      };
    
initializeApp(firebaseConfig);

  
// Initialize Firebase Messaging
const messaging = getMessaging();

// Request permission and get token
export const requestForToken = async () => {
  let currentToken = '';
  try {
    currentToken = await getToken(messaging, { vapidKey: 'BGHeP5PiqA2a_eWM_ACZnTluTr0xUFW-G2-tOuXZ7KXliqZyzMcS0sjTt0t9oxoeJnp6kjATdDv7ojb0Uf8Qgpw'});
    if (currentToken) {
      console.log('FCM Token:', currentToken);
      localStorage.setItem('fcmToken',currentToken)
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (error) {
    console.error('An error occurred while retrieving token. ', error);
  }
  return currentToken;
};


let role_Type = window.localStorage.getItem("role_Type_Instructor")

export const getInstrcutorLogin = (data) =>{
    return{
        type: GET_INSTRUCTOR_LOGIN,
        payload: data
    }
}

export const setInstructorToken = (data)=>{
    return{
        type: SET_INSTRUCTOR_TOKEN,
        payload: data?.data
    }
}

export const getInstructorMenus = (data)=>{
    return{
        type: GET_INSTRUCTOR_MENUS,
        payload: data.result?.auth_objects
    }
}


export const getInstructorLoginRole = (data)=>{
    return{
        type:GET_INSTRUCTOR_LOGIN_ROLE,
        payload: data
    }
}

export const getCurrentInstructorGroups = (data)=>{
    return{
        type:SET_CURRENT_INSTRUCTOR_GROUPS,
        payload:data
    }
}

export const getCurrentInstructorGroupInformation = (data)=>{
        return{
            type:SET_CURRENT_INSTRUCTOR_GROUP_INFORMATION,
            payload:data
        }
}


export const retrieveCurrentInstructorGroups = (payload)=>{
    console.log(payload,"grouppayloadInstructror")
    return(dispatch)=>{
      axios.post(apiConfig.baseUrl+"e_lms/v1/batch",payload
      ).then((res)=>{
          
          if (res.data?.res_status == true) {
            console.log(res?.data)
              dispatch(getCurrentInstructorGroups(res?.data))
              dispatch(instructorMenus())

          }

          if (res?.data?.res_status == false) {
              dispatch(getCurrentInstructorGroups([]))
              dispatch(instructorMenus())

          }
               
      }).catch(err=>{
          console.log(err)
      })
}
  }


  //groupinfor
  export const retrireveInstructorGroupInformation = (payload)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/fcmtoken",payload
        ).then((res)=>{
            
            if (res.data?.res_status == true) {
              console.log(res?.data)
                dispatch(getCurrentInstructorGroupInformation(res?.data))
                // dispatch(instructorMenus())
  
            }
  
            if (res?.data?.res_status == false) {
                dispatch(getCurrentInstructorGroupInformation([]))
                // dispatch(instructorMenus())
  
            }
                 
        }).catch(err=>{
            console.log(err)
        })
  } 
  }

  //end


  

export const instructorMenus = (paylod)=>{
    let roleType = window.localStorage.getItem("role_TypeInstructor")
    let data
    if (paylod !==null) {
         data ={
            "idm_roles": [
              roleType
            ],
            "appl_name": "Lms"
        }
    }
    
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl1+"security_auth/authorization/list_menus",data,{headers}
        ).then((res)=>{
            
            if (res.data?.res_status == true) {
                dispatch(getInstructorMenus(res?.data))
            }
                 
        }).catch(err=>{
            console.log(err)
        })
}
  }


  export const createRecordByFirebaseTokenInstructor = (payload)=>{
    return(dispatch)=>{
      axios.post(apiConfig.baseUrl1+"comm_hub/firebase_registration",payload
      ).then((res)=>{
          console.log(res?.data,"exit")
              
      }).catch(err=>{
          console.log(err)
      })
}
  }

export const instructorLogin = (data)=>{
    return(dispatch)=>{
        axios.post(apiConfig.baseUrl+"e_lms/v1/instructor_login",data,{headers}
        ).then((res)=>{
            
            if (res.data?.res_status == true) {
                // requestForToken()
                getFirebaseToken()
                  .then((firebaseToken) => {
                    if (firebaseToken) {
                      // alert('Firebase token: ' + firebaseToken);
                        console.log(firebaseToken,'firebaseToken')  // Using alert to display the token
                      window.localStorage.setItem("fcmToken",firebaseToken)
                    } else {
                      alert('No Firebase token received.');  // Inform user if no token received
                    }
                  })
                  .catch((err) => {
                    console.error('An error occurred while retrieving the Firebase token. ', err);
                    // alert('An error occurred while retrieving the Firebase token.');  // Alerting the user about the error
                    alert('Your browser\'s notification permissions are denied. Please accept the required permissions to receive the notifications');

                  });
                  
                dispatch(instructorMenus())
                dispatch(createRecordByFirebaseTokenInstructor())
                dispatch(getInstrcutorLogin(res))

            }
            if (res.data?.res_status == false) {
                dispatch(getInstrcutorLogin(res))
             }
        }).catch(err=>{
            console.log(err)
        })
}
}

export const insertGroupChart = (data)=>{
    return{
      type:INSTRUCTOR_INSERT_GROUP_CHART,
      payload:data
    }
  }
  
  export const insertFireBaseGroupChart = (data)=>{
  return{
    type:INSTRUCTOR_INSERT_GROUP_CHART_FIRE_BASE,
    payload:data
  }
  }
  

  export const insertChartDetails = (payload)=>{

    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
      ).then((res)=>{
  
          if (res.data.res_status == true) {
  
              dispatch(insertGroupChart(res))
          }
  
          if (res?.data.res_status == false) {
              dispatch(insertGroupChart())
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }
  
  export const insertFireBaseChartDetails = (payload) => {
    return (dispatch) => {
      axios.post("https://botpi.arkatiss.com/ecomm/v2/firebase_chat", payload)
        .then((res) => {
          if (res.data.res_status == true) {
            dispatch(insertFireBaseGroupChart(res));
  
            
            setTimeout(()=>{
                let getPayload = {
                    "peer_type": "retrieve",
                    "peer_name": payload?.peer_name
                  }
                  dispatch(retrieveInstructorMessages(getPayload));
            },2000)
           
          }
  
          if (res?.data.res_status == false) {
            dispatch(insertFireBaseGroupChart());
          }
        })
        .catch(err => {
          console.log(err);
        });
    };
  };
  

  
  export const getInstructorMessage = (data)=>{
    return {
        type:FETCH_INSTRUCTOR_MESSAGES,
        payload:data
        
    }
  }

  export const retrieveInstructorMessages = (payload)=>{
    return(dispatch)=>{
      
        axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
        ).then((res)=>{
    
            if (res?.data.res_status == true) {
                dispatch(getInstructorMessage(res?.data))
            }
    
            if (res?.data.res_status == false) {
                dispatch(getInstructorMessage(res?.data))
            }
                 
        }).catch(err=>{
            console.log(err)
        })
    }
  }

  export const retrieveGroupChartDetails = (data)=>{
    return{
      type:RETRIEVE_GROUP_CHART_DETAILS,
      payload:data
    }
  }
  
  export const retrieveGroupCharList = (payload)=>{
  
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/groupchat",payload
      ).then((res)=>{
  
  
  
          if (res?.data?.res_status === true) {
  
  
            // console.log(res?.data.res_status,'AVNI B')
  
            // console.log(res?.data)
              dispatch(retrieveGroupChartDetails(res.data))
          }
  
          if (res?.data?.res_status === false) {
              dispatch(retrieveGroupChartDetails([]))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const instructorContactUsDetails = (data)=>{
    return{
      type:INSTRUCTORE_CONTCAT_US,
      payload:data
    }
  }
  
  export const instructorContactUs = (payload)=>{
  
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/contact_us",payload
      ).then((res)=>{
  
  
  
          if (res?.data?.res_status === true) {
  
  
            // console.log(res?.data.res_status,'AVNI B')
  
            // console.log(res?.data)
              dispatch(instructorContactUsDetails(res))
          }
  
          if (res?.data?.res_status === false) {
              dispatch(instructorContactUsDetails(res))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }


  export const forgotPasswordApiCallInstructor = (payload)=>{
  
    return(dispatch)=>{
      
      axios.post(apiConfig.baseUrl+"e_lms/v1/forgot_password",payload
      ).then((res)=>{
  
  
  
          if (res?.data?.res_status === true) {
  
  
            // console.log(res?.data.res_status,'AVNI B')
  
            // console.log(res?.data)
              dispatch(instructorContactUsDetails(res))
          }
  
          if (res?.data?.res_status === false) {
              dispatch(instructorContactUsDetails(res))
          }
               
      }).catch(err=>{
          console.log(err)
      })
  }
  }